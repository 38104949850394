import {meteEnvConfig} from 'config';
import {DEBUG_TELLY_SDK_CLICK_EVENT} from 'shared/constants';
import getRandomCoordinatesInRectangle from 'shared/utils/getRandomCoordinatesInRectangle';
import {logger as baseLogger} from 'shared/utils/logger';

import {handleTellySdkMessages} from './handleTellySdkMessages';

import {triggerCustomEvent} from '.';

const logger = baseLogger.child({tag: '[SDK Click]'});

/**
 * Sends a click action to the TellySDK at the specified coordinates.
 * @param {DOMRect} adCoordinates - The coordinates of the ad
 * @param {string} display - The display where the ad will expand
 * @return {void}
 */
function sendTellySdkClick(adCoordinates: DOMRect, display: string | undefined): void {
  try {
    const coordinates = getRandomCoordinatesInRectangle(adCoordinates);

    if (window?.TellyAds?.click !== undefined) {
      logger.debug('Sent click action', {coordinates});
      window.TellyAds.click(coordinates.x, coordinates.y, display && display !== '' ? display : 'THEATER');

      if (meteEnvConfig.ads.debug.tellySdkClickEvents) {
        triggerCustomEvent(DEBUG_TELLY_SDK_CLICK_EVENT, {x: coordinates.x, y: coordinates.y});
      }
      logger.debug('TellyAds.click ', {coordinates});
    } else if (window?.TellyAds?.click === undefined && !__DEV__) {
      logger.warn('The TellySDK is not defined.');
    } else {
      /** Function for debugging messages in DEV mode. */
      triggerCustomEvent(DEBUG_TELLY_SDK_CLICK_EVENT, {x: coordinates.x, y: coordinates.y});
      handleTellySdkMessages(coordinates as unknown as {data: Record<string, unknown>});
      logger.debug('The TellySDK in DEV mode will not be defined.');
      logger.debug('Sent action emulation for __DEV__', {coordinates});
    }
  } catch (error) {
    logger.warn('Error reading TellySDK: ', error);
  }
}

export default sendTellySdkClick;
