import {parseVast} from 'features/vast-tracker/service';
import {VAST_FILE_RECEIVED_EVENT} from 'shared/constants';
import {eventEmitter} from 'shared/utils/eventEmitter';
import {logger as baseLogger} from 'shared/utils/logger';

import type EventEmitter from 'events';
import type {VastParsedData, VastTrackerResponse} from 'types/vast-client';

const logger = baseLogger.child({tag: '[Cache VAST]'});

/**
 * Cache for parsed VAST data. We do need this to reduce time we lost for parsing.
 */
export class VastCacheManager {
  emitterCallback: ((e: Record<'vastFile', string>) => void) | undefined;

  private items: Record<string, VastParsedData> = {};
  private emitter: EventEmitter;

  /**
   * Initialize class constructor
   * @param {EventEmitter} emitter
   */
  constructor(emitter: EventEmitter) {
    this.emitter = emitter;
  }

  /**
   * Register event
   */
  public register() {
    logger.debug('Emitter Registered');
    this.emitterCallback = (e: Record<'vastFile', string>)=> {
      this.put(e.vastFile).catch();
    };

    this.emitter.on(VAST_FILE_RECEIVED_EVENT, this.emitterCallback);
  }

  /**
   * Clear emitter
   */
  public unregister() {
    if (this.emitterCallback) {
      logger.debug('Emitter Clear');
      this.emitter.removeListener(VAST_FILE_RECEIVED_EVENT, this.emitterCallback);
      this.emitterCallback = undefined;
    }
  }

  /**
   * Remove item from cache
   * @param {string} vastUrl
   */
  public remove(vastUrl: string) {
    delete this.items[vastUrl];
  }

  /**
   * Parse and put data into local cache
   * @param {string} vastFile
   */
  private async put(vastFile: string) {
    try {
      this.items[vastFile] = await parseVast(vastFile);
      logger.debug(`Put file into cache: ${vastFile}`);
    } catch (e) {
      logger.warn(`Cannot get vastData: ${e.toString()}`);
    }
  }

  /**
   * get cached data
   * @param {string} vastFile
   * @return {VastTrackerResponse | null}
   */
  public get(vastFile: string): VastTrackerResponse | null {
    const item = this.items[vastFile];

    if (item) {
      logger.debug(`Getting from cache: ${vastFile}`);
    } else {
      logger.debug(`Cannot find pre-cached: ${vastFile}`);
    }

    return item? {data: [...this.items[vastFile]], error: null} as VastTrackerResponse : null;
  }
}

export const vastCacheManager = new VastCacheManager(eventEmitter);
