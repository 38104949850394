import {lazy, type ReactNode, useEffect, useRef, useState} from 'react';

import {v4 as uuid} from 'uuid';

import {withSuspense} from 'app/providers';
import Freestar from 'components/Ads/Freestar';
import TellyAd from 'components/Ads/Telly';
import {BarkerVideo} from 'components/Ads/TypeChannel/barker-video';
import {useMute} from 'components/Ads/TypeVideo/helpers/useMute';
import {meteEnvConfig} from 'config';
import {useAdUnitConfigContext} from 'context';
import {AdTypes} from 'features/adoppler';
import {AdContainer, CountdownTracker} from 'shared/components';
import {ADOPPLER_RENDER_EVENT, COUNTDOWN_CONTAINER_HEIGHT} from 'shared/constants';
import {subscribe, unsubscribe} from 'shared/utils';
import {AndroidSDKEvent} from 'shared/utils/eventsSdk';
import LaunchDarklyService from 'shared/utils/launch-darkly-service';
import {logger as baseLogger} from 'shared/utils/logger';
import permutationService from 'shared/utils/permutation-service';

import type {ParsedResponse, UseAdReturnType} from 'types';

import type {Tag} from 'features/adoppler';
import type {NativeAd} from 'features/adoppler/types/native';

const LazyType500 = lazy(() => import('components/Ads/Type500'));
const LazyType502 = lazy(() => import('components/Ads/Type502'));
const LazyType503 = lazy(() => import('components/Ads/Type503'));
const LazyType511 = lazy(() => import('components/Ads/Type511'));
const LazyTypeVideo = lazy(() => import('components/Ads/TypeVideo'));
const LazyTypePng = lazy(() => import('components/Ads/TypePng'));
const LazyTypeAdPod = lazy(() => import('components/Ads/TypeVideo/ad-pod'));

const logger = baseLogger.child({tag: '[Adoppler Component]'});

const googletag = window.googletag || {};
googletag.cmd = googletag.cmd || [];

// Getting and setting to local storage the Telly app object list.
permutationService.setList();

interface Props {
  settings: {
  googleCanBePlayed: boolean;
  eagerLoading: boolean;
  }
}

export const AdopplerAd = (props: Props): ReactNode | null => {
  const {eagerLoading, googleCanBePlayed} = props.settings;

  const pngAdTypes = [
    AdTypes.Png,
    AdTypes.InhousePng,
    AdTypes.AdamPngUp,
    AdTypes.AdamPngDown,
    AdTypes.AdamPngCenter,
  ];

  const {adUnitConfig} = useAdUnitConfigContext();

  const featureFlags = adUnitConfig.features;
  const mutedRef = useMute();

  const [{adSettings, adResponseId, adType, disableAdEvents, adPodVasts, isAcr}, setData] = useState<UseAdReturnType>({
    adSettings: [],
    adResponseId: '',
    adType: AdTypes.DefaultTelly,
    disableAdEvents: false,
    isAcr: false,
  });

  const sdkEventRef = useRef(new AndroidSDKEvent());
  const refreshKeyRef = useRef(uuid());

  const sdkEvent = sdkEventRef.current;
  const adServer = LaunchDarklyService.getFlag('active-ad-server', 'elemental');

  useEffect(() => {
    const handleFetchEvent = (event: Event) => {
      const customEvent = (event as CustomEvent).detail;
      logger.debug(`Received 'RENDER' event`, customEvent);
      setData(customEvent);
      logger.debug('Ad settings:', customEvent.adSettings);
      refreshKeyRef.current = uuid();
    };

    subscribe(ADOPPLER_RENDER_EVENT, handleFetchEvent);
    return () => {
      unsubscribe(ADOPPLER_RENDER_EVENT, handleFetchEvent);
      permutationService.destroy();
    };
  }, []);

  if (
    !adType
    || (adSettings.length == 0 && adType !== AdTypes.Google && adType !== AdTypes.DefaultTelly)
    || adType === AdTypes.DefaultTelly
  ) {
    logger.debug('no ad urls, show TellyLogo', adType, adSettings);
    return <TellyAd key={adResponseId} loaded={!!adResponseId} />;
  }

  // For 'Google' the logger.info event defined in the Freestar service.
  if (adType !== AdTypes.Google) {
    logger.info('Render event ad with details', {
      adType,
      isAcr: isAcr || false,
      creativeId: adSettings[0]?.adBid?.crid,
      campaignId: adSettings[0]?.adBid?.cid,
      'type-of-ad': adServer, // So as not to be confused with adType.
    });
  }

  const getProps = (adSettings: ParsedResponse[]) => ({
    adTrackers: adSettings[0].adTrackers,
    sdkEvent,
    adImg: adSettings[0].adImg,
    tag: `Type${adType}` as Tag,
    adClickTracker: adSettings[0].adClickTracker as NativeAd.Link,
    ext: adSettings[0].ext,
    bid: adSettings.map((n) => n.adBid)[0],
  });

  const getAdPodProps = (adSettings: ParsedResponse[], adResponseId: string) => ({
    ...getProps(adSettings),
    adPodVasts: adPodVasts || [],
    adBids: adSettings.map((n) => n.adBid).flat(1),
    defaultSettings: meteEnvConfig.ads.video,
    adResponseId: adResponseId!,
    mutedRef,
    disableAdPodEvents: disableAdEvents,
  });

  const getComponentByType = (adType: AdTypes): ReactNode | null => {
    if (pngAdTypes.includes(adType)) {
      return withSuspense(LazyTypePng)({...getProps(adSettings),
        isOldTV: featureFlags?.showButtonB?.enable === false,
      });
    }

    switch (adType) {
      case (AdTypes.HtmlBanner):
        return withSuspense(LazyType500)({...getProps(adSettings),
          adUrl: adSettings[0].adUrls[0],
          defaultQabElement: featureFlags?.showButtonB?.enable ? 'button-b' : 'default-ad'});

      case (AdTypes.Expandable):
        return withSuspense(LazyType502)({...getProps(adSettings), adUrls: adSettings[0].adUrls});

      case (AdTypes.EveFullscreenTakeover):
        return withSuspense(LazyType503)({
          ...getProps(adSettings),
          adImg: adSettings[0].adImg,
          adFrame: adSettings[0].adFrame,
          key: refreshKeyRef.current,
        });

      case (AdTypes.EveFullscreen):
        return withSuspense(LazyType511)({
          ...getProps(adSettings),
          adUrls: [adSettings[0].adUrls[0], adSettings[0].adUrls[0]],
        });

      case (AdTypes.Video):
        return withSuspense(LazyTypeVideo)({
          ...getProps(adSettings),
          adClickTracker: adSettings[0].adClickTracker as string[],
          adUrl: adSettings[0].adUrls[0],
          defaultSettings: meteEnvConfig.ads.video,
          bid: getProps(adSettings).bid,
        });

      case (AdTypes.AdPod):
        return withSuspense(LazyTypeAdPod)({
          ...getAdPodProps(adSettings, adResponseId!),
        });

      case (AdTypes.Barker):
        return <BarkerVideo
          url={adSettings[0].adUrls[0] || ''}
          barkerData={adSettings[0].barkerData}
          videoConfig={{
            autoplay: meteEnvConfig.ads.video.autoplay,
            mute: mutedRef.current,
          }}
        />;

      case (AdTypes.Google):
        return (googleCanBePlayed && !eagerLoading)
          ? <Freestar key={refreshKeyRef.current} show={true} />
          : null;

      default:
        return <TellyAd/>;
    }
  };

  return (
    <>
      <AdContainer>
        {getComponentByType(adType)}
        {(googleCanBePlayed && eagerLoading) && <Freestar show={adType === AdTypes.Google} />}
      </AdContainer>
      {
        __DEV__ && <CountdownTracker
          element={{
            title: `${adType} Type`,
            top: COUNTDOWN_CONTAINER_HEIGHT,
          }}
        />
      }
    </>
  );
};
