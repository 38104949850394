import {isAxiosError} from 'axios';
import QRCodeStyling from 'qr-code-styling';

import {logger as baseLogger} from 'shared/utils/logger';

import {axiosInstance} from './service-url';

const logger = baseLogger.child({tag: '[QR Code Generator]'});

/**
 * Class responsible for generating a styled QR code.
 */
class QRCodeGenerator {
  private qrCode: QRCodeStyling;

  /**
   * Creates an instance of QRCodeGenerator.
   * The constructor initializes a new QRCodeStyling object with rounded dots and finder patterns.
  */
  constructor() {
    this.qrCode = new QRCodeStyling({
      width: 135,
      height: 135,
      dotsOptions: {
        color: '#FFFFFF',
        type: 'rounded',
      },
      cornersSquareOptions: {
        color: '#FFFFFF',
        type: 'extra-rounded',
      },
      cornersDotOptions: {
        color: '#FFFFFF',
      },
      backgroundOptions: {
        color: 'transparent',
      },
      qrOptions: {
        errorCorrectionLevel: 'L',
      },
    });
  }

  /**
   * Retrieves the QR code as a base64-encoded image.
   * This can be directly used as a `src` in an <img> tag.
   * @param {string} longURL - The URL or data to encode in the QR code.
   * @param {string} exp - number of seconds when short url is not expired
   * @return {Promise<string | null>} - Returns a promise that resolves to a Base64 string that can be embedded into an img.src.
   */
  async getQRCodeAsBase64(longURL: string, exp: number): Promise<string | null> {
    const url = await this.shortenUrl(longURL, exp);

    this.qrCode.update({
      data: url,
    });

    const blob = await this.qrCode.getRawData('svg');

    if (blob) {
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result as string;
          resolve(base64data);
          logger.debug('Processed QR Code as Base64 from the URL:', url);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    }

    return null;
  }

  /**
   * Shortens a given URL using the Bitly API.
   * @param {string} longUrl - The original long URL to shorten.
   * @param {string} exp - number of seconds when short url is not expired
   * @return {string} A promise that resolves to the shortened URL.
   * @throws Will throw an error if the URL shortening fails.
   */
  async shortenUrl(longUrl: string, exp: number): Promise<string> {
    try {
      const expirationTime = new Date(Date.now() + exp * 1000);

      const response = await axiosInstance.post<{ shortenedUrl: string }>(
        '/register',
        {originalUrl: longUrl, expireAt: expirationTime.toISOString()},
      );

      const shortUrl = response.data.shortenedUrl;
      logger.debug('Successfully shortened URL:', shortUrl);
      return shortUrl;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        logger.warn('Failed to shorten URL:', error.message);
      } else {
        logger.warn('An unknown error occurred', error);
      }
      return longUrl;
    }
  }
}

export default QRCodeGenerator;
