import {logger as baseLogger} from 'shared/utils';

import {handleTellySdkMessages} from './handleTellySdkMessages';

import type {TellySDKActionType} from 'features/adoppler';

const logger = baseLogger.child({tag: '[SDK Action]'});

/**
 * Sends an action to the Telly SDK with the provided data.
 *
 * @param {string} action - The Telly SDK action to be performed.
 * @param {Record<string, unknown>} data - The data associated with the action.
 * @return {void}
 */
function sendTellySdkAction<T extends Record<string, unknown>>(action: TellySDKActionType, data: T): void {
  try {
    if (data) {
      const stringifiedData = JSON.stringify(data);

      if (window?.TellySDK?.action !== undefined) {
        logger.debug('Sent action', {action, stringifiedData});
        window.TellySDK.action(action, stringifiedData);
      } else if (window?.TellySDK?.action === undefined && !__DEV__) {
        logger.warn('The TellySDK is not defined.');
      } else {
        /** Function for debugging messages in DEV mode. The TellySDK in DEV mode will not be defined. */
        handleTellySdkMessages(data as unknown as {data: Record<string, unknown>});
      }
    }
  } catch (error) {
    logger.warn('Error reading TellySDK: ', error);
  }
}

export default sendTellySdkAction;
