import React, {Suspense} from 'react';

const withSuspense = <P extends object>(LazyComponent: React.ComponentType<P>) => {
  return ({key, ...props}: P & {key?: React.Key}) => (
    <Suspense>
      <LazyComponent {...(props as P)} key={key} />
    </Suspense>
  );
};

export default withSuspense;
