import type {DeviceInfo} from 'types';

/**
 * Extracts the user identifier based on the hash type and optional provider.
 *
 * @param {DeviceInfo} deviceInfo - The device information containing user identifiers and alternate IDs.
 * @param {'md5' | 'sha256' | 'uid2'} hash - The hash type of the user identifier.
 * @param {'aps' | 'criteo'} [provider] - The optional provider for non-standard hashes.
 * @return {string | undefined} - The extracted user identifier or undefined if not found.
 */
export function getUserId(
  deviceInfo: DeviceInfo | undefined,
  hash: 'md5' | 'sha256' | 'uid2' | 'sha1',
  provider?: 'aps' | 'criteo',
): string | undefined {
  if (!deviceInfo) return undefined;

  if (hash === 'uid2') {
    return (
      deviceInfo.user_identifiers?.uid2
        ?? deviceInfo.alternateIds?.['uid2-sha256-base64-email']
    );
  }

  if (hash === 'sha1') {
    return (
      deviceInfo.alternateIds?.['uid2-sha1-base64-email']
    );
  }

  if (hash === 'sha256') {
    if (provider === 'aps') {
      return deviceInfo.alternateIds?.['aps-sha256-hex-email']
        ?? deviceInfo.user_identifiers?.aps;
    }
    if (provider === 'criteo') {
      return deviceInfo.alternateIds?.['criteo-sha256-hex-email'];
    }
    return deviceInfo.alternateIds?.['uid2-sha256-base64-email'];
  }

  if (hash === 'md5' && provider === 'criteo') {
    return deviceInfo.alternateIds?.['criteo-md5-hex-email'];
  }

  return undefined;
}
