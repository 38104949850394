import type {ReactNode} from 'react';

import {v4 as uuid} from 'uuid';

import {AbstractStrategy} from 'entities/strategies/api/abstract';
import generateAdJSX from 'entities/strategies/ui/generateAdJSX';
import {AdTypes} from 'features/adoppler/enums';
import fetchAd, {parseAdopplerResponse} from 'features/adoppler/service/fetch';
import {
  ACR_AD_RECEIVED_EVENT,
  ADOPPLER_RENDER_EVENT,
  FREESTAR_NOT_LOADED_EVENT,
  GOOGLE_AD_EMPTY_EVENT,
  VIDEO_AD_FINISHED_EVENT,
} from 'shared/constants';
import {
  getDirectVideoFromResponse,
  getPgVideoFromResponse,
  logger as baseLogger,
  subscribe,
  triggerCustomEvent,
  unsubscribe,
  eventEmitter,
  getAdFromResponse,
} from 'shared/utils';
import {acrService} from 'shared/utils/acr-service';
import {vastCacheManager} from 'shared/utils/cache-manager/vast.ts';
import permutationService from 'shared/utils/permutation-service';

import type {AdConfig, AdStrategyName, DeviceInfo, ParsedResponse, UseAdReturnType} from 'types';

import type {AdStrategy} from 'entities/strategies/model';
import type {AdopplerResponse} from 'features/adoppler';

const logger = baseLogger.child({tag: '[AdopplerMultiBidStrategy]'});

const ACR_AD_FREQUENCY = 120000;

/**
 * Represents Adoppler MultiBid strategy
 * @implements {AdStrategy}
 */
class AdopplerMultiBidStrategy extends AbstractStrategy implements AdStrategy {
  acrAd: UseAdReturnType | null;
  videoFinishHandler: (() => void) | undefined;
  acrEventHandler: ((response: AdopplerResponse) => void) | undefined;
  googleEmptyHandler: ((e: Event) => void) | undefined;
  googleScriptNotLoadedHandler: VoidFunction | undefined;
  timeoutId: NodeJS.Timeout | undefined;
  name: AdStrategyName;
  awaitPromise: ((result: boolean) => void) | undefined;
  loopStart: boolean = false;
  adTypePlaying: AdTypes | undefined = AdTypes.DefaultTelly; // type to determine what ads is playing
  startPlaying: number = Date.now(); // timestamp to determine when ads start playing
  googleCanBePlayed: boolean = true;
  adsPerTick: number = 2; // determine how many ads should we show for 1 cycle tick
  renderAmount: number = 0; // times component were rendered during cycle tick
  googleRetriesAttempts: number = 5;

  /**
   * Creates an instance of the AdopplerMultiBidStrategy.
   *
   * @constructor
   * @param {DeviceInfo | null} deviceProps - DeviceProps we need to pass to handle validation
   * @param {AdConfig} adUnitConfig - application config object
   */
  constructor(deviceProps: DeviceInfo | null, adUnitConfig: AdConfig) {
    super();
    this.name = 'adoppler-multibid-strategy';
    this.deviceProps = deviceProps;
    this.adUnitConfig = adUnitConfig;
    this.acrAd = null;
  }

  /**
   * Run and managing fetching loop
   * @param {number} displayInterval
   */
  public startLoop(displayInterval: number): void {
    logger.debug('Started strategy cycle');

    vastCacheManager.register();
    acrService.listenForEvent();

    this.loopStart = true;
    (async () => {
      this.videoFinishHandler = () => {
        if (this.adTypePlaying !== AdTypes.Video) {
          return;
        }

        // we have active request, we should stop it before send new one on demand
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }

        if (this.awaitPromise) {
          this.awaitPromise(true);
        }
      };

      this.googleEmptyHandler = async (e: Event) => {
        // we have active request, we should stop it before send new one on demand
        if (this.adTypePlaying !== AdTypes.Google) {
          return;
        }

        const eventTimestamp = (e as CustomEvent).detail.timestamp;
        if ((eventTimestamp - this.startPlaying) < displayInterval) {
          triggerCustomEvent(ADOPPLER_RENDER_EVENT, {
            adType: AdTypes.DefaultTelly,
            adSettings: [],
            adResponseId: uuid(),
          });
          await new Promise((r) => setTimeout(r, displayInterval));
        }

        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }

        if (this.awaitPromise) {
          this.awaitPromise(true);
        }
      };

      this.googleScriptNotLoadedHandler = () => {
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }

        if (this.awaitPromise) {
          this.awaitPromise(true);
        }

        this.googleCanBePlayed = (this.googleRetriesAttempts-- > 0)? this.googleCanBePlayed : false;
        logger.warn((this.googleCanBePlayed)? `FreeStar was not loaded, let's retry...`
          : `FreeStar load attempts reached, giving up`);
      };

      this.acrEventHandler = (response: AdopplerResponse) => {
        logger.debug('Processing ACR response', response);
        this.acrAd = parseAdopplerResponse(
          {response, type: AdTypes.MultiBid, dataUpdatedAt: Date.now()},
        );
      };

      eventEmitter.on(ACR_AD_RECEIVED_EVENT, this.acrEventHandler);
      subscribe(VIDEO_AD_FINISHED_EVENT, this.videoFinishHandler);
      subscribe(GOOGLE_AD_EMPTY_EVENT, this.googleEmptyHandler);
      subscribe(FREESTAR_NOT_LOADED_EVENT, this.googleScriptNotLoadedHandler);

      while (this.loopStart) {
        await this.run(displayInterval);
      }
    })();
  }

  /**
   * Fetch response from the cache
   * @protected
   * @return {Promise<UseAdReturnType>}
   */
  protected async getResponse(): Promise<UseAdReturnType> {
    const response = await fetchAd(AdTypes.MultiBid);
    if (response.adType === AdTypes.DefaultTelly) {
      response.adType = AdTypes.Google;
    }

    return {...response, isAcr: false};
  }

  /**
   * Unsubscribe from active videoHandler
   */
  protected unsubscribeVideoHandler() {
    if (this.videoFinishHandler) {
      unsubscribe(VIDEO_AD_FINISHED_EVENT, this.videoFinishHandler);
      this.videoFinishHandler = undefined;
    }
  }

  /**
   * Unsubscribe from active googleEmptyHandler
   */
  protected unsubscribeGoogleHandler() {
    if (this.googleEmptyHandler) {
      unsubscribe(GOOGLE_AD_EMPTY_EVENT, this.googleEmptyHandler);
      this.googleEmptyHandler = undefined;
    }
  }

  /**
   * Unsubscribe from active googleScriptNotLoadedHandler
   */
  protected unsubscribeGoogleScriptLoaded() {
    if (this.googleScriptNotLoadedHandler) {
      unsubscribe(FREESTAR_NOT_LOADED_EVENT, this.googleScriptNotLoadedHandler);
      this.googleScriptNotLoadedHandler = undefined;
    }
  }

  /**
   * Unsubscribe from active googleScriptNotLoadedHandler
   */
  protected unsubscribeAcrService() {
    if (this.acrEventHandler) {
      eventEmitter.removeListener(ACR_AD_RECEIVED_EVENT, this.acrEventHandler);
      acrService.removeListeners();
      this.acrEventHandler = undefined;
    }
  }

  /**
   * Show any display ads we have in response
   * @param {Object} options - The options for sending the render event
   * @param {AdTypes} options.adType - The type of the ad to render
   * @param {ParsedResponse} [options.adSettings] - The parsed ad settings
   * @param {UseAdReturnType} [options.adResponse] - The ad response object
   * @param {number} [options.displayInterval] - The ad display interval in milliseconds
   * @return {Promise<boolean>} - A promise that resolves to a boolean indicating whether the render event was sent successfully
   * @protected
   */
  protected async sendRenderEvent(options: {
    adType: AdTypes;
    adSettings?: ParsedResponse;
    adResponse?: UseAdReturnType;
    displayInterval?: number;
  }): Promise<boolean> {
    const {adType, adSettings, adResponse, displayInterval} = options;

    this.adTypePlaying = adType;
    this.startPlaying = Date.now();

    return new Promise((resolve) => {
      this.awaitPromise = resolve;
      triggerCustomEvent(ADOPPLER_RENDER_EVENT, {
        adType: adType,
        adSettings: [adSettings],
        adResponseId: adResponse?.adResponseId,
        isAcr: adResponse?.isAcr,
      });

      if (displayInterval) {
        this.timeoutId = setTimeout(() => {
          resolve(true);
        }, displayInterval);
      }
    });
  }

  /**
   * Return random display interval for each time if it was configured
   * @return {number | undefined}
   * @protected
   */
  protected getRandomInterval(): number | undefined {
    const displayTiming = this.adUnitConfig?.providers.gabriel.googleTagManager?.displayTiming;
    if (displayTiming) {
      const {maxDisplayTiming: max, minDisplayTiming: min} = displayTiming;
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  }

  /**
   * Render direct video
   * @param {UseAdReturnType} response
   * @protected
   */
  protected async renderDirectVideo(response: UseAdReturnType) {
    const parsedDirectVideoAd = getDirectVideoFromResponse(response.adSettings);
    if (parsedDirectVideoAd) {
      if (this.isValid(parsedDirectVideoAd)) {
        logger.debug(`Showing 'DIRECT VIDEO'`);
        this.renderAmount++;
        await this.sendRenderEvent({
          adType: parsedDirectVideoAd.adType,
          adResponse: response,
          adSettings: parsedDirectVideoAd,
        });
      } else {
        logger.debug(`Validation for 'DIRECT VIDEO' failed, trying to show 'DISPLAY' AD`);
      }
    } else {
      logger.debug(`No 'DIRECT VIDEO' found, trying to show 'DISPLAY' AD`);
    }
  }

  /**
   * Render Png component
   * @param {UseAdReturnType} response
   * @param {displayInterval} displayInterval
   * @protected
   */
  protected async renderPng(response: UseAdReturnType, displayInterval: number) {
    const parsedImageAd = getAdFromResponse(response.adSettings, AdTypes.Png);
    const isDeeplink = parsedImageAd?.ext?.creative_type?.action?.type === 'deeplink';
    const isDongleAvailable =
      Boolean(this.adUnitConfig?.features?.skipDeeplink?.enable) === false && permutationService.isDongleAvailable();

    if (!parsedImageAd) {
      logger.debug(`No 'DISPLAY' AD found, trying to show 'EXPANDABLE' AD`);
      return;
    }

    if (isDeeplink && !isDongleAvailable) {
      logger.debug(`Dongle is not connected. DEEPLINK Ad has been skipped.`);
      return;
    } else {
      if (this.isValid(parsedImageAd)) {
        logger.debug(`Showing 'DISPLAY'`);
        this.renderAmount++;
        await this.sendRenderEvent({
          adType: parsedImageAd.adType,
          adResponse: response,
          adSettings: parsedImageAd,
          displayInterval:
            parsedImageAd.ext?.override_duration
              ? parsedImageAd.ext.override_duration * 1000
              : displayInterval,
        });
        return;
      } else {
        logger.debug(`Validation for 'DISPLAY' failed, trying to show 'EXPANDABLE' AD`);
      }
    }

    logger.debug(`Validation for 'DISPLAY' failed, trying to show 'EXPANDABLE' AD`);
  }
  /**
   * Try to render expandable component
   * @param {UseAdReturnType} response
   * @param {number} displayInterval
   * @protected
   */
  protected async renderExpandable(response: UseAdReturnType, displayInterval: number) {
    if (this.renderAmount >= this.adsPerTick) {
      return;
    }

    const parsedExpandableAd = getAdFromResponse(response.adSettings, AdTypes.Expandable);
    if (parsedExpandableAd) {
      if (this.isValid(parsedExpandableAd)) {
        logger.debug(`Showing 'EXPANDABLE'`);
        this.renderAmount++;
        await this.sendRenderEvent({
          adType: parsedExpandableAd.adType,
          adResponse: response,
          adSettings: parsedExpandableAd,
          displayInterval,
        });
      } else {
        logger.debug(`Validation for 'EXPANDABLE' failed, trying to show 'PG VIDEO' AD`);
      }
    } else {
      logger.debug(`No 'EXPANDABLE' AD found, trying to show 'PG VIDEO' AD`);
    }
  }

  /**
 * Renders a "takeover" ad if a valid ad is found in the response and the render limit is not reached.
 *
 * This function processes the response to check for a takeover ad type. If a valid ad is found and passes validation,
 * it triggers the rendering of the ad and increments the `renderAmount` counter. If no valid ad is found or validation fails,
 * it logs the appropriate messages and moves to the next ad type.
 *
 * @param {UseAdReturnType} response - The response containing ad settings and configurations.
 * @param {number} displayInterval - The interval for displaying the ad in milliseconds.
 * @return {Promise<void>} A promise that resolves once the takeover ad rendering process is complete.
 */
  protected async renderTakeover(response: UseAdReturnType, displayInterval: number): Promise<void> {
    if (this.renderAmount >= this.adsPerTick) {
      return;
    }

    const parsedExpandableAd = getAdFromResponse(response.adSettings, AdTypes.EveFullscreenTakeover);
    if (parsedExpandableAd) {
      if (this.isValid(parsedExpandableAd)) {
        logger.debug(`Showing 'EVE_TAKEOVER'`);
        this.renderAmount++;
        await this.sendRenderEvent({
          adType: parsedExpandableAd.adType,
          adResponse: response,
          adSettings: parsedExpandableAd,
          displayInterval,
        });
      } else {
        logger.debug(`Validation for 'EVE_TAKEOVER' failed, trying to show 'PG VIDEO' AD`);
      }
    } else {
      logger.debug(`No 'EVE_TAKEOVER' AD found, trying to show 'PG VIDEO' AD`);
    }
  }

  /**
   * Render programmatic video
   * @param {UseAdReturnType} response
   * @protected
   */
  protected async renderPgVideo(response: UseAdReturnType) {
    if (this.renderAmount >= this.adsPerTick) {
      return;
    }

    const parsedPgVideoAd = getPgVideoFromResponse(response.adSettings);
    if (parsedPgVideoAd) {
      if (this.isValid(parsedPgVideoAd)) {
        logger.debug(`Showing 'PG VIDEO'`);
        this.renderAmount++;
        await this.sendRenderEvent({
          adType: parsedPgVideoAd.adType,
          adSettings: parsedPgVideoAd,
          adResponse: response,
        });
      } else {
        logger.debug(`Validation for 'PG VIDEO' failed, trying to show 'HTML Banner' AD`);
      }
    } else {
      logger.debug(`No 'PG VIDEO' found, trying to show 'HTML Banner' AD`);
    }
  }

  /**
   * Render html banner
   * @param {UseAdReturnType} response
   * @param {displayInterval} displayInterval
   * @protected
   */
  protected async renderHtmlBanner(response: UseAdReturnType, displayInterval: number) {
    if (this.renderAmount >= this.adsPerTick) {
      return;
    }

    const htmlResponse = getAdFromResponse(response.adSettings, AdTypes.HtmlBanner);
    if (htmlResponse) {
      if (this.isValid(htmlResponse)) {
        logger.debug(`Showing 'HTML Banner'`);
        this.renderAmount++;
        await this.sendRenderEvent({
          adType: htmlResponse.adType,
          adResponse: response,
          adSettings: htmlResponse,
          displayInterval:
            htmlResponse.ext?.override_duration
              ? htmlResponse.ext.override_duration * 1000
              : displayInterval,
        });
      } else {
        logger.debug(`Validation for 'HTML Banner' failed`);
      }
    } else {
      logger.debug(`No 'HTML Banner' AD found`);
    }
  }

  /**
   * Fetch acr ad
   * @protected
   * @return {UseAdReturnType | null}
   */
  protected fetchAcrAd(): UseAdReturnType | null {
    const ad = !acrService.ignoreAcrRequest && this.acrAd ? this.acrAd : null;
    if (ad) {
      acrService.ignoreAcrRequest = true;
      setTimeout(() => {
        logger.debug('Ready to show new ACR');
        acrService.ignoreAcrRequest = false;
      }, ACR_AD_FREQUENCY);
    }
    this.acrAd = null;

    return ad && ad.adSettings.length ? {
      ...ad,
      // Choose random item
      adSettings: [ad.adSettings[Math.trunc(ad.adSettings.length * Math.random())]],
      isAcr: true,
    } : null;
  }

  /**
   * Runs the ad fetching and rendering loop.
   *
   * @param {number} displayInterval - The interval between ad fetches.
   * @return {Promise<void>}
   * @protected
   */
  protected async run(displayInterval: number): Promise<void> {
    this.adTypePlaying = undefined;
    // if we have acr ad show acr otherwise take regular ad
    const response = this.fetchAcrAd() || await this.getResponse();

    this.renderAmount = 0;

    // direct video -> 501 -> 502 -> programmatic video -> 500
    await this.renderDirectVideo(response);
    await this.renderPng(response, displayInterval);
    await this.renderExpandable(response, displayInterval);
    await this.renderPgVideo(response);
    await this.renderHtmlBanner(response, displayInterval);
    await this.renderTakeover(response, displayInterval);

    // if no type was reproduced - display Google ad
    if (!this.adTypePlaying) {
      if (this.googleCanBePlayed) {
        logger.debug(`No 'PAID' AD found, show GOOGLE`);
        await this.sendRenderEvent({
          adType: AdTypes.Google,
          displayInterval: this.getRandomInterval() ?? displayInterval,
        });
      } else {
        logger.debug('FreeStar was not loaded, skip GOOGLE');
        await this.sendRenderEvent({
          adType: AdTypes.DefaultTelly,
          displayInterval,
          adResponse: {adResponseId: uuid(), adSettings: []},
        });
      }
    }

    logger.debug(`End of cycle`);
  }

  /**
   * Stop running fetching loop
   */
  public stopLoop(): void {
    this.loopStart = false;
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    vastCacheManager.unregister();
    this.unsubscribeVideoHandler();
    this.unsubscribeGoogleHandler();
    this.unsubscribeGoogleScriptLoaded();
    this.unsubscribeAcrService();

    // show default logo
    triggerCustomEvent(ADOPPLER_RENDER_EVENT, {adType: AdTypes.DefaultTelly,
      adResponse: {adResponseId: uuid(), adSettings: []},
    });
  }

  /**
   * Retrieves an ad using the strategy.
   *
   * @return {ReactNode | null} The JSX element representing the selected ad.
   */
  public getAd(): ReactNode | null {
    return generateAdJSX(this.name, this.startLoop.bind(this), this.stopLoop.bind(this), this.googleCanBePlayed);
  }
}

export {AdopplerMultiBidStrategy};
