import {useEffect, useState} from 'react';

import {DEBUG_TELLY_SDK_CLICK_EVENT} from 'shared/constants';
import {logger} from 'shared/utils/logger';

import './styles.css';

type TellySDKClickEvent = CustomEvent<{x: number, y: number}>;

interface ClickDot {
  id: number;
  x: number;
  y: number;
}

const TellySDKClickEventsDebugger = () => {
  const [clickDots, setClickDots] = useState<ClickDot[]>([]);

  const handleAnimationEnd = (dotId: number) => {
    setClickDots((currentDots) => currentDots.filter((dot) => dot.id !== dotId));
  };

  const handleSDKClickEvent = (event: Event) => {
    const clickEvent = event as TellySDKClickEvent;

    logger.debug('Got TellySDKClickEvent:', {x: clickEvent.detail.x, y: clickEvent.detail.y});

    const newDot: ClickDot = {
      id: Date.now(),
      x: clickEvent.detail.x,
      y: clickEvent.detail.y,
    };

    setClickDots((currentDots) => [...currentDots, newDot]);
  };

  const handleLifecycle = () => {
    logger.debug('Initializing TellySDKClickEventsDebugger...');

    document.addEventListener(DEBUG_TELLY_SDK_CLICK_EVENT, handleSDKClickEvent);

    return () => {
      logger.debug('Destroying TellySDKClickEventsDebugger...');

      document.removeEventListener(DEBUG_TELLY_SDK_CLICK_EVENT, handleSDKClickEvent);
    };
  };

  useEffect(handleLifecycle, []);

  return (
    <div className="click-dots-container">
      {clickDots.map((dot) => (
        <div
          key={dot.id}
          onAnimationEnd={() => handleAnimationEnd(dot.id)}
          className='click-dot'
          style={{
            width: 20,
            height: 20,
            left: dot.x - 10,
            top: dot.y - 10,
          }}
        />
      ))}
    </div>
  );
};

export default TellySDKClickEventsDebugger;
