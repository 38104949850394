import React, {type RefObject} from 'react';
import {useEvent} from 'react-use';

import {triggerClickTrackers} from 'features/adoppler/helpers/click-trackers';
import {gabrielAnalyticMetrics} from 'shared/api/metrics-service';
import {RC_BUTTON_PRESS_EVENT, USER_ACTIVITY_DETECT_EVENT} from 'shared/constants';
import {analytics, sendTellySdkAction, sendTellySdkClick, logger as baseLogger} from 'shared/utils';
import {globalStorage} from 'shared/utils/globalStorage';
import permutationService from 'shared/utils/permutation-service';
import {typeGuard} from 'shared/utils/permutation-service/type-guard';

import type {
  AdCreativeConfig,
  DeeplinkAction,
  EventOpenURLPayloadTarget,
  ExtResponseAdConfig,
  OpenUrlAction,
  QuarkUrlAction,
  QrAction,
  TellySDKActionUrlData,
} from 'features/adoppler';

interface Payload {
  button: 'A' | 'B'
}

const logger = baseLogger.child({tag: '[useAdClick Hook]'});

const remoteButtonMap: Record<'a_button' | 'b_button', Payload['button']> = {
  a_button: 'A',
  b_button: 'B',
};

interface UseAndroidSDKEventProps {
  crid: string | undefined;
  clickUrl: string | undefined;
  clickTrackers: string[],
  adNode?: RefObject<HTMLElement>;
  isClickable?: boolean;
  isVideoCreative?: boolean;
  ext: ExtResponseAdConfig | undefined
}

/**
 * Hook to handle Android SDK button click events and trigger corresponding actions.
 * @param {Object} props - The properties required for the hook.
 * @param {string} props.crid - Creative ID.
 * @param {string} props.clickUrl - Click URL for the ad.
 * @param {string[]} props.clickTrackers - click-trackers array
 * @param {RefObject<HTMLElement>} props.adNode - The ad node that will be used to get the coordinates for the TellySDKClickEvent.
 * @param {boolean} props.isClickable - Whether the ad is clickable.
 * @param {boolean} props.isVideoCreative - Whether the ad is video creative.
 */
const useAdClick = ({
  crid,
  clickUrl,
  clickTrackers,
  adNode,
  isClickable,
  isVideoCreative,
  ext,
}: UseAndroidSDKEventProps) => {
  const {creative_type: adCreativeConfig} = ext ?? {};
  const trackersDeps = JSON.stringify(clickTrackers);
  const creativeDeps = JSON.stringify(adCreativeConfig);
  const isV2Permutation = 'version' in (adCreativeConfig ?? {}) && adCreativeConfig?.version?.startsWith('2');

  React.useEffect(() => {
    logger.debug('useAdClick function: ', {
      crid,
      clickUrl,
      clickTrackers,
      adCreativeConfig,
      isClickable,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crid, clickUrl, trackersDeps, creativeDeps, isClickable]);

  useEvent(RC_BUTTON_PRESS_EVENT, (event: CustomEvent<{ payload: Payload }>) => {
    logger.debug(`Detected ${RC_BUTTON_PRESS_EVENT} event`, event.detail, ' isClickable: ', isClickable);

    const adCoordinates = adNode?.current?.getBoundingClientRect();

    if (isClickable) {
      if (event.detail.payload.button !== 'B') {
        return;
      }

      if (!adCoordinates) {
        logger.warn(`Event ${RC_BUTTON_PRESS_EVENT} will not be processed. Ad coordinates are not ready.`);
        return;
      }

      logger.debug(`Event ${RC_BUTTON_PRESS_EVENT} will be processed with handleTellySDKClickEvent`, {adCoordinates});

      if (isV2Permutation && adCreativeConfig?.action) {
        const {action} = adCreativeConfig;
        const {button} = event.detail.payload;
        permutationV2sendEventHandle({action, adCoordinates, button, clickTrackers, crid, clickThroughURL: clickUrl});
        return;
      }
      handleTellySDKClickEvent(adCoordinates, crid, adCreativeConfig?.ad_conversion_target_display);
      if (isVideoCreative) {
        globalStorage.nonceManager?.sendAdClick();
      }
      if (clickTrackers.length > 0) {
        triggerClickTrackers(crid, clickTrackers);
      }
    } else if (clickUrl) {
      const creativeConfig = {...adCreativeConfig};
      if (isV2Permutation && typeGuard.isOpenUrlParams(creativeConfig.action)) {
        const params = (adCreativeConfig!.action as OpenUrlAction).params;
        creativeConfig.ad_conversion_trigger = params.trigger;
        creativeConfig.ad_conversion_target_display = params.screen;
      }
      logger.debug(`Event ${RC_BUTTON_PRESS_EVENT} ${clickUrl} processed with handleAndroidSDKButtonEvent`);
      handleAndroidSDKButtonEvent(event, crid, clickUrl, clickTrackers, creativeConfig);
    }

    logger.debug(`Event ${RC_BUTTON_PRESS_EVENT} processed`, event.detail);
  });

  useEvent(USER_ACTIVITY_DETECT_EVENT, (event: CustomEvent<{ payload: Payload }>) => {
    // TODO: Then here should be handled USER_ACTIVITY_DETECT_EVENT.
    logger.debug(`${USER_ACTIVITY_DETECT_EVENT} was detected: ${JSON.stringify(event)}`);
  });
};

/**
 * Handles Android Ads button events and triggers the click.
 * @param {DOMRect} adCoordinates - The coordinates of the ad.
 * @param {string | undefined} crid - Creative ID.
 * @param {string | undefined} display - The display where the ad will expand
 */
const handleTellySDKClickEvent = (
  adCoordinates: DOMRect,
  crid: string | undefined,
  display: string | undefined,
) => {
  sendTellySdkClick(adCoordinates, display);
  analytics.emitAdEvent('Interaction', {creative_id: crid ?? '', creative_permutation_type: 'eve_house_ad_qab'});
  gabrielAnalyticMetrics.emitEvent('AdInteraction');
  logger.debug('Emit click to Programmatic Ad to Android SDK');
};

/**
 * Handles Android SDK button events and triggers corresponding actions.
 * @param {CustomEvent<{ payload: Payload }>} event - The button event.
 * @param {string | undefined} crid - Creative ID.
 * @param {string | undefined} clickUrl - Click URL for the ad.
 * @param {string[]} clickTrackers - Click trackers we need to emulate to call
 * @param {AdCreativeConfig | undefined} adCreativeConfig - Ad creative configuration.
 */
const handleAndroidSDKButtonEvent = (
  event: CustomEvent<{ payload: Payload }>,
  crid: string | undefined,
  clickUrl: string,
  clickTrackers: string[],
  adCreativeConfig: AdCreativeConfig | undefined,
) => {
  const {payload} = event.detail;
  logger.debug('Click to button: ', payload.button);

  if (!adCreativeConfig?.ad_conversion_target_display
      || !adCreativeConfig?.ad_conversion_trigger
      || adCreativeConfig?.ad_conversion_trigger === 'none') {
    logger.warn('adCreativeParams missing! Watch [Ad Parsing Validator] warnings.');
    return;
  }

  const {
    ad_conversion_target_display: targetedDisplay,
    // TODO: Investigate. ad_creative_type_name have to be removed!
    ad_creative_type_name: permutationType,
    ad_conversion_trigger: remoteButton,
  } = adCreativeConfig;

  if (payload.button === remoteButtonMap[remoteButton]) {
    sendTellySdkAction<TellySDKActionUrlData>(
      'ACTION_URL',
      {display: targetedDisplay as EventOpenURLPayloadTarget, url: clickUrl},
    );
    analytics.emitAdEvent('Interaction', {creative_id: crid ?? '', creative_permutation_type: permutationType ?? ''});
    gabrielAnalyticMetrics.emitEvent('AdInteraction');
    logger.debug('Emit clickAdTracker of Android SDK: ', {url: clickUrl, targetedDisplay});

    if (clickTrackers.length > 0) {
      triggerClickTrackers(crid, clickTrackers);
    } else {
      logger.warn('adClickTracker missing! Watch [Ad Parsing Validator] warnings.');
    }
  }
};

interface PermutationV2sendEventHandleParams {
  action: QrAction | OpenUrlAction | DeeplinkAction | QuarkUrlAction;
  adCoordinates: DOMRect;
  crid: string | undefined;
  button: 'B' | 'A';
  clickTrackers: string[];
  clickThroughURL: string | undefined;
}

const permutationV2sendEventHandle = (params: PermutationV2sendEventHandleParams) => {
  const {action, adCoordinates, crid, button, clickTrackers, clickThroughURL} = params;

  if (typeGuard.isDeeplinkParams(action)) {
    const deeplinkObj = permutationService.combineTTMDeeplink(action);

    if (deeplinkObj) {
      sendTellySdkAction<TellyAppObject.Root>('DEEPLINK', {...deeplinkObj});
    } else {
      logger.warn('Deeplink object has not been created. See [Permutation Service] logs');
    }

    handleTellySDKClickEvent(adCoordinates, crid, action.params.screen);
    return;
  } else if (typeGuard.isQuarkUrlParams(action)) {
    const {params: {
      display,
      trigger,
      url,
      skipReady,
      templateId,
    } } = action;
    if (button === remoteButtonMap[trigger]) {
      sendTellySdkAction<TellySDKActionUrlData>(
        'ACTION_QUARK',
        {display: display as EventOpenURLPayloadTarget, url, skipReady, templateId},
      );
      analytics.emitAdEvent('Interaction', {
        creative_id: crid ?? '',
        creative_permutation_type: '',
      });
      logger.debug('Emit clickAdTracker of Android SDK: ', {quark_url: url, screen, skipReady, templateId});

      if (clickTrackers.length > 0) {
        triggerClickTrackers(crid, clickTrackers);
      } else {
        logger.warn('adClickTracker missing! Watch [Ad Parsing Validator] warnings.');
      }
    }
    return;
  } else if (typeGuard.isOpenUrlParams(action)) {
    const {params: {screen, trigger, browser} } = action;

    // Telly have 2 browsers: Xperi and Quark
    const apiAction = browser === 'quark' ? 'ACTION_QUARK' : 'ACTION_URL';

    if (button === remoteButtonMap[trigger]) {
      sendTellySdkAction<TellySDKActionUrlData>(
        apiAction,
        {display: screen as EventOpenURLPayloadTarget, url: clickThroughURL!},
      );
      analytics.emitAdEvent('Interaction', {
        creative_id: crid ?? '',
        // TODO: Remove creative_permutation_type
        creative_permutation_type: '',
      });
      gabrielAnalyticMetrics.emitEvent('AdInteraction');
      logger.debug('Emit clickAdTracker of Android SDK: ', {clickThroughURL, screen});

      if (clickTrackers.length > 0) {
        triggerClickTrackers(crid, clickTrackers);
      } else {
        logger.warn('adClickTracker missing! Watch [Ad Parsing Validator] warnings.');
      }
    }
    return;
  } else if (typeGuard.isQrParams(action)) {
    const url = clickThroughURL ?? 'https://freetelly.com';
    sendTellySdkAction<TellySDKActionUrlData>(
      'ACTION_URL',
      {display: 'none', url},
    );
    logger.debug('Generated QR with URL: ', {url, screen: 'none'});
    return;
  }
};

export default useAdClick;
