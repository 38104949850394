import pino, {levels} from 'pino';

import {meteEnvConfig} from 'config';

import {sendEventToParent} from './event';
import LaunchDarklyService from './launch-darkly-service';

/**
 * Configuration type for logger settings.
 * @property {string} name - The name of the logger.
 * @property {LevelWithSilent} level - The log level.
 * @property {(logEvent: LogEvent) => void} send - Function to send log messages.
 */
type LoggerConfig = {
  name: string,
  level: pino.LevelWithSilent,
  send: (logEvent: pino.LogEvent) => void
}

/**
 * Extracts and returns an array of messages from the log event.
 * @param {LogEvent} logEvent - The log event.
 * @return {Array<unknown>} An array of messages to be logged.
 */
export const getMessagesFromLogEvent = (logEvent: pino.LogEvent): unknown[] => {
  const messages = [...logEvent.messages];
  const [binding] = logEvent.bindings;
  if (binding?.tag) {
    messages.unshift(binding?.tag);
  }
  const adUnit = meteEnvConfig.ads.adUnit;
  messages.unshift(adUnit);
  return messages;
};

/**
 * Converts an array of messages to a string.
 * @param {Array<unknown>} messages - An array of messages to be stringified.
 * @return {string} A string representation of the messages.
 */
export const stringifyLogMessage = (messages: unknown[]) => {
  return messages.map((m) => {
    try {
      let cache: unknown[] = [];
      const stringified = JSON.stringify(m, (_, value) => {
        if (typeof value === 'object' && value !== null) {
          // Duplicate reference found, discard key
          if (cache.includes(value)) return;

          // Store value in our collection
          cache.push(value);
        }
        return value;
      });
      cache = [];
      return stringified;
    } catch (error) {
      console.warn('Error stringify log message', error);
      return '';
    }
  }).join(' ');
};

/**
 * Array of logger configurations.
 * @type {Array<LoggerConfig>}
 */
export const loggerConfig: Array<LoggerConfig> = [
  {
    name: 'web-view-logger',
    level: 'trace',
    send: (logEvent: pino.LogEvent) => {
      const messages = getMessagesFromLogEvent(logEvent);
      const stringified = stringifyLogMessage(messages);
      sendEventToParent(stringified);
    },
  }, {
    name: 'browser-logger',
    level: 'trace',
    send: (logEvent: pino.LogEvent) => {
      const browserLoggerBindings: {[key in pino.Level]: (message?: unknown, ...optionalParams: unknown[]) => void} = {
        trace: console.trace,
        debug: console.debug,
        info: console.info,
        warn: console.warn,
        error: console.error,
        fatal: console.error,
      };

      const log = browserLoggerBindings[logEvent.level.label as pino.Level];
      const messages = getMessagesFromLogEvent(logEvent);
      const stringified = stringifyLogMessage(messages);
      if (__DEV__) {
        log(...messages);
      } else {
        log(stringified);
      }
    },
  },
];

/**
 * Set of child loggers.
 * @type {Set<pino.Logger>}
 */
export const children = new Set<pino.Logger>([]);

/**
 * Logger instance configured with custom log transmission.
 * @type {pino.Logger}
 */
export const logger = pino({
  // Changes all logger instances that used in a project.
  onChild: (instance: pino.Logger) => children.add(instance),
  serializers: {
    error: pino.stdSerializers.err,
  },
  browser: {
    // block default broswer output because of 'transmit' gives more detailed logEvent
    write() {},
    serialize: true,
    transmit: {
      /**
       * Custom log transmission function.
       * @param {pino.Level} _level - The log level of the current log event.
       * @param {pino.LogEvent} logEvent - The log event being transmitted.
       */
      send: (_level: pino.Level, logEvent: pino.LogEvent) => {
        flagObserver();
        for (const service of loggerConfig) {
          if (levels.values[service.level] <= logEvent.level.value) {
            service.send(logEvent);
          }
        }
      },
    },
  },
});

/**
 * Observes and updates the log level based on the LaunchDarkly feature flag.
 */
export function flagObserver() {
  const serviceLevel = LaunchDarklyService.getFlag('system-logging-level');
  if (!serviceLevel?.length || serviceLevel == logger.level) return;

  logger.level = serviceLevel;
  children.forEach((child) => child.level = serviceLevel);
}
