import React, {useRef} from 'react';

import {QuickActionButton} from 'shared/components';
import {useHeartBeat} from 'shared/hooks';
import {cn} from 'shared/utils';
import {logger as baseLogger} from 'shared/utils/logger';

import {useFreestar} from './useFreestar';

import type {ExtResponseAdConfig} from 'features/adoppler';

import './index.css';

const logger = baseLogger.child({tag: '[Freestar Component]'});
const houseCreativeType = {
  ad_conversion_target_display: 'THEATER',
  ad_component_type: 'full',
  ad_conversion_trigger: 'b_button',
  ad_creative_type_name: 'eve_house_ad_qab',
} satisfies ExtResponseAdConfig['creative_type'];

const FreestarComponent = ({show}: {show: boolean}) => {
  const {size: adSizeClassName, displayRectangleByAspectRatio, defaultQabElement, crid} = useFreestar();
  const adNode = useRef<HTMLDivElement>(null);

  const isHouseAd = adSizeClassName === 'house-ad-rectangle';
  const baseClassName = show ? adSizeClassName : 'hidden-ad';
  const fallbackClassName = displayRectangleByAspectRatio ? 'rectangle' : 'native-gpt';
  const computedClassName = cn(show ? baseClassName || fallbackClassName : 'hidden-ad');

  useHeartBeat(show, 'freestar');

  logger.debug('Render with size', adSizeClassName);

  return (
    <>
      <div
        ref={adNode}
        id='native-ad-fs'
        data-testid="telly-freestar-ad"
        className={computedClassName}
      />
      <QuickActionButton
        defaultElement={defaultQabElement}
        adClickTracker={{clicktrackers: [], url: ''}}
        displayControlsBlock={
          show && displayRectangleByAspectRatio
        }
        crid={crid}
        ext={{creative_type: isHouseAd ? houseCreativeType : undefined}}
        isClickable={show && (isHouseAd || defaultQabElement === 'button-b')}
        adNode={adNode}
      />
    </>
  );
};

const Freestar = React.memo(FreestarComponent);

export default Freestar;
