import {AdTypes} from 'features/adoppler/enums';
import {getAd, getAdPod} from 'shared/api/ad/adoppler/lib';
import {parseResponse} from 'shared/utils';
import {AndroidSDKEvent} from 'shared/utils/eventsSdk';
import {logger as baseLogger} from 'shared/utils/logger';

import type {UseAdReturnType} from 'types';

import type {WrappedResponse} from 'features/adoppler';

const logger = baseLogger.child({tag: '[Adoppler UseAd Hook]'});
const sdkEvent = new AndroidSDKEvent();

/**
 * Handles errors that occur during the process of fetching and setting Adoppler ad.
 *
 * @param {Error} error - The error object that occurred during the ad fetching process.
 */
function fetchAndSetAdopplerAdErrorHandle(error: unknown | Error) {
  sdkEvent.errorGabrielInit(JSON.stringify(error));
  logger.warn('Error while parsing Adoppler response', error);
}

export const parseAdopplerResponse = (
  wrappedResponse: WrappedResponse | void,
): UseAdReturnType => {
  const responseData = {
    adSettings: [],
    adResponseId: '',
    adType: AdTypes.DefaultTelly,
  } as UseAdReturnType;

  logger.debug(`Parsing Adoppler response`, {wrappedResponse});

  try {
    if (wrappedResponse && 'response' in wrappedResponse) {
      const {response, type} = wrappedResponse;
      if (response && type) {
        responseData.adResponseId = response.id;
        responseData.adType = type;
        const parsedResponse = parseResponse(response, type);
        if (parsedResponse != null && parsedResponse?.length > 0 && parsedResponse[0].adUrls && type) {
          responseData.adSettings = parsedResponse;
        } else {
          logger.warn('Adoppler missing parameter in response');
        }
      } else {
        logger.warn('No response from Adoppler');
      }
    } else {
      fetchAndSetAdopplerAdErrorHandle('No response from Adoppler');
    }
  } catch (error) {
    fetchAndSetAdopplerAdErrorHandle(error);
  }

  return responseData;
};

/**
 * Custom hook to manage ad-related state and fetching.
 * @param {AdTypes} adType
 * @param {number} adPodDuration
 * @return {UseAdReturnType}
 */
export default async function fetchAd(
  adType: AdTypes,
  adPodDuration?: number,
): Promise<UseAdReturnType> {
  const response = await (adType === AdTypes.AdPod ? getAdPod(adPodDuration!) : getAd(adType));
  return parseAdopplerResponse(response);
}
