import {meteEnvConfig} from 'config';
import {AdTypes} from 'features/adoppler';

import type {ExpandableAdConfig, ParsedResponse} from 'types';

import {ConfigFactory} from './config';
import {logger as baseLogger} from './logger';
import {adParsing} from './validators';

import type {
  EventTracker,
  ExpandCollapseResponse,
  ExtResponseAdConfig,
  AdopplerResponse,
  Bid,
} from 'features/adoppler';
import type {NativeAd} from 'features/adoppler/types/native';

const logger = baseLogger.child({tag: '[Adoppler Response Parser]'});

export const sortAdopplerAdUrls = (bid: Bid): string[] => {
  const adBundle = JSON.parse(bid.adm) as ExpandCollapseResponse;

  const adImages = adBundle.native.assets
    // Sort the images by width to get the smaller one in the first position
    .sort((a, b) => (a.img.w < b.img.w ? -1 : 1))
    .map((asset) => asset.img.url);

  logger.debug('Parsed adImages from response', adImages);
  return [adImages[0], adImages[1]];
};

/**
 * Parse response from the server
 * @param {Response} response
 * @param {number | string} adType
 * @return {ParsedResponse[] | undefined}
 */
export const parseResponse = (response: AdopplerResponse, adType: number | string): ParsedResponse[] | undefined => {
  logger.debug('Parsing response', {adType, response});
  try {
    if (response.seatbid.length < 1) {
      return undefined;
    }

    const bids = response.seatbid.reduce<Bid[]>((acc, seatbid) => {
      return acc.concat(seatbid.bid);
    }, []);
    return bids.map((bid) => {
      const parsedAdType = adType === AdTypes.MultiBid ? getAdTypeFromResponse(bid) : adType;
      const nativeAd = parseNativeAdImg(bid, parsedAdType);

      return {
        adType: parsedAdType,
        adUrls: getAdUrl(bid, parsedAdType),
        adTrackers: ([AdTypes.Video, AdTypes.AdPod].includes(parsedAdType as AdTypes)) ? [] : getAdTrackers(bid),
        adConfig: getAdConfig(bid),
        adBid: bid,
        adImg: nativeAd?.adImg,
        adFrame: nativeAd?.adFrame,
        adClickTracker: ([AdTypes.Video, AdTypes.AdPod].includes(parsedAdType as AdTypes))
          ? [] : parseAdClickTracker(bid),
        ext: parseExt(bid),
      } as ParsedResponse;
    });
  } catch (e) {
    logger.error('Error while parsing response', e, response);
  }
};

/**
 * Get adType parsed from bid object
 * @param {Bid} bid
 * @return {AdTypes}
 */
export const getAdTypeFromResponse = (bid: Bid): AdTypes => {
  try {
    const adm = JSON.parse(bid.adm) as NativeAd.Adm;
    if (adm.native.assets[0].img) {
      return adm.native.assets[0].img['type'] as AdTypes;
    }

    return adm.native.assets[0].data['type'] as AdTypes;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return AdTypes.Video;
  }
};

export const getAdUrl = (bid: Bid, adType: number | string): string[] | undefined => {
  logger.debug('Parsed adUrls from the bid', {adType, bid});
  const expandableTypes = [AdTypes.Expandable];

  try {
    if (adType === AdTypes.HtmlBanner) {
      const adm = JSON.parse(bid.adm);
      const value = adm.native.assets[0].data.value;

      return [value];
    }

    if (expandableTypes.includes(adType as AdTypes)) {
      return sortAdopplerAdUrls(bid);
    }

    if ([AdTypes.Video, AdTypes.AdPod].includes(adType as AdTypes)) {
      return [bid.adm];
    }

    return [];
  } catch (error) {
    logger.error('Error while parsing Ad URLs', error);
    return [];
  }
};

export const parseNativeAdImg = (bid: Bid, adType: number | string) => {
  logger.debug('Parsed native ad img from response', {adType, bid});

  const pngTypes = [
    AdTypes.Png,
    AdTypes.InhousePng,
    AdTypes.AdamPngUp,
    AdTypes.AdamPngDown,
    AdTypes.AdamPngCenter,
  ];

  try {
    if (
      pngTypes.includes(adType as AdTypes)
      || adType === AdTypes.Expandable
      || adType === AdTypes.EveFullscreenTakeover
    ) {
      const adm = JSON.parse(bid.adm) as NativeAd.Adm;

      let adImg: NativeAd.Img | undefined;
      let adFrame: NativeAd.Frame | undefined;

      adm.native.assets.forEach((asset) => {
        if (asset.img) {
          adImg = asset.img;
        }

        if (asset.data?.value) {
          adFrame = asset.data;
        }
      });

      return {adImg, adFrame};
    }
  } catch (error) {
    logger.error('Error while parsing AdImg URLs', error);
  }
};

export const getAdTrackers = (bid: Bid): EventTracker[] | undefined => {
  logger.debug('Parse AdTrackers from the bid', bid);

  try {
    const adm = JSON.parse(bid.adm);
    const adTrackers = adm.native.eventtrackers as EventTracker[];
    const parsedAdTracker = adTrackers.map((adTracker) => ({
      ...adTracker,
      url: adTracker.url.replace(/\\"}\\"$/g, ''),
    }));
    logger.debug('Parsed AdTrackers', parsedAdTracker);

    return parsedAdTracker;
  } catch (error) {
    logger.error('Error while parsing AdTrackers', error);
  }
};

export const parseAdClickTracker = (bid: Bid): NativeAd.Link | null => {
  logger.debug('Parse AdClickTrackers from', bid);
  try {
    const adm = JSON.parse(bid.adm) as NativeAd.Adm;
    if (!('link' in adm.native)) return null;

    const {value, error, warning} = adParsing.validateParseAdClickTracker(adm.native);

    if (error || warning) {
      logger.warn(error);

      return (value as NativeAd.Impression)?.link ?? null;
    }

    logger.debug('Parsed AdClickTrackers', adm.native.link);

    return adm.native?.link ?? null;
  } catch (error) {
    if (meteEnvConfig.ads.adUnit !== 'ADAM_SCREENSAVER_VIDEO_UNIT') {
      logger.error('Error while parsing AdClickTrackers', error);
    }
    return null;
  }
};

/**
   * This function retrieves the configuration for an expandable advert.
   *
   * @param {Bid} bid - Object containing server response. The response is expected
   * to have a `seatbid` property which is an array. The first element of this array should
   * be an object containing a `bid` array. The first element of the bid array should be an
   * object containing an `ext` object and within that a `telly` object. The `telly` object
   * contains configuration for the ad such as FULL_SCREEN_AD_DURATION and
   * INITIAL_WAITING_TIME_TO_EXPAND.
   *
   * The function will log any errors encountered during execution and return undefined.
   *
   * @return {ExpandableAdConfig | undefined} - ExpandableAdConfig object with properties:
   *  - `INITIAL_WAITING_TIME_TO_EXPAND`: If parsed value from response is NaN, this property
   *    defaults to the value from `defaultExpandableAdConfig. INITIAL_WAITING_TIME_TO_EXPAND`.
   *    Else, it will be the parsed value from response.
   *  - `FULL_SCREEN_AD_DURATION`: If parsed value from response is NaN, this property defaults
   *    to the value from `defaultExpandableAdConfig.FULL_SCREEN_AD_DURATION`. Else, it will
   *    be the parsed value from response.
   *  - `DEFAULT_EXPAND_ANIMATION_DURATION`: Directly taken from
   *    `defaultExpandableAdConfig.DEFAULT_EXPAND_ANIMATION_DURATION`.
   *
   * If an error occurs during execution the function will return `undefined`.
   */
export const getAdConfig = (bid: Bid): ExpandableAdConfig | undefined => {
  try {
    const config = bid?.ext?.telly;
    if (!config) return;

    const configFactory = new ConfigFactory();

    // here expandable type is hardcoded
    const adConfig = configFactory.createConfig('expandable', config);

    logger.debug('Parsed AdConfig', config);

    return adConfig;
  } catch (error) {
    logger.warn('Error while parsing AdConfig', error);
  }
};

/**
 * Parses the `ext` property from a given response object.
 * @param {Bid} bid - Object containing server response. The response is expected
 * @return {AdCreativeConfig | undefined} - The parsed `ext` property, or undefined if parsing fails or data is missing.
 */
export function parseExt(bid: Bid): ExtResponseAdConfig | undefined {
  try {
    if (!bid?.ext) return undefined;

    const {error, value, warning} = adParsing.validateExt(bid.ext);

    if (error || warning) logger.warn(error);

    return value as ExtResponseAdConfig;
  } catch (error) {
    logger.warn('Error while parsing Ext field', error);
  }
}

/**
 * Try to find the first adType from response
 * @param {ParsedResponse[]} settings
 * @param {AdTypes} adType
 * @return {ParsedResponse | undefined}
 */
export const getAdFromResponse = (settings: ParsedResponse[], adType: AdTypes): ParsedResponse | undefined => {
  return settings.find((node) => (node.adType === adType));
};

/**
 * Try to find direct video from the response
 * @param {ParsedResponse[]} settings
 * @return {ParsedResponse | undefined}
 */
export const getDirectVideoFromResponse = (settings: ParsedResponse[]): ParsedResponse | undefined => {
  return settings.find((node) => (node.adType === AdTypes.Video && node.ext !== undefined));
};

/**
 * Try to find programmatic video from the response
 * @param {ParsedResponse[]} settings
 * @return {ParsedResponse | undefined}
 */
export const getPgVideoFromResponse = (settings: ParsedResponse[]): ParsedResponse | undefined => {
  return settings.find((node) => (node.adType === AdTypes.Video && node.ext === undefined));
};
