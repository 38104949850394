import type {NamedSize} from 'types/google-ad';

export const GOOGLE_AD_EMPTY_EVENT = 'GOOGLE_AD_EMPTY_EVENT';
export const VIDEO_AD_FINISHED_EVENT = 'VIDEO_AD_FINISHED_EVENT';
export const VIDEO_REACHED_PLAYING_TIME_THRESHOLD_EVENT = 'VIDEO_REACHED_PLAYING_TIME_THRESHOLD_EVENT';

// measured in seconds, describes when we should trigger an event
export const VIDEO_BEFORE_DURATION_END_THRESHOLD_S = 5;

export const AD_FINISHED_EVENT = 'AD_FINISHED_EVENT';
export const FREESTAR_NOT_LOADED_EVENT = 'FREESTAR_NOT_LOADED_EVENT';
export const WORKER_REGISTER_CUSTOM_AD_POD = 'WORKER_REGISTER_CUSTOM_AD_POD';
export const WORKER_JOB_MAKE_CALL = 'WORKER_JOB_MAKE_CALL';
export const REQUEST_FAILED_EVENT = 'REQUEST_FAILED_EVENT';

export const TELLY_EVENT = 'telly';
export const GABRIEL_READY_EVENT = 'GABRIEL_READY';

export const ANDROID_SDK_EVENT = 'android.sdk';
export const RC_BUTTON_PRESS_EVENT = 'remote_control_button_press';
export const USER_ACTIVITY_DETECT_EVENT = 'user_activity_detected';
export const DEBUG_TELLY_SDK_CLICK_EVENT = 'debug_telly_sdk_click_event';

export const SWAP_PAUSE_TIME = 5 * 1000;
export const COUNTDOWN_CONTAINER_HEIGHT = '5rem';

export const ADOPPLER_RENDER_EVENT = 'ADOPPLER_RENDER_EVENT';

export const VAST_FILE_RECEIVED_EVENT = 'VAST_FILE_RECEIVED_EVENT';

export const ACR_TELLY_SDK_EVENT = 'acr';
export const ACR_AD_RECEIVED_EVENT = 'ACR_AD_RECEIVED_EVENT';

export const APP_LIST_UPDATE_EVENT = 'appListUpdate' as const;

export const VIDEO_BUFFERING_TIMEOUT_MS = 6 * 1000; // 6 seconds.

export const GPT_NAMED_SIZE: NamedSize = {
  'banner-s': [320, 50],
  'banner-m': [320, 100],
  leaderboard: [728, 90],
  'leaderboard-l': [970, 90],
  rectangle: [300, 250],
  'rectangle-l': [336, 280],
  'rectangle-xl': [348, 290],
  'half-page': [300, 600],
  'video-m': [640, 360],
  'video-l': [1280, 720],
  square: [100, 100],

  'rectangle-s-custom': [554, 250],
  'rectangle-m-custom': [570, 360],
  'house-ad-rectangle': [514, 290],
  'prebid-size': [1, 1],
};
