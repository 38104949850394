import {logger as baseLogger} from 'shared/utils/logger';

const logger = baseLogger.child({tag: '[Triggering ClickTracker Event]'});

/**
 * Trigger all clickTrackers for the bid
 * @param {string | undefined} crid
 * @param {string[]} clickTrackers
 */
export const triggerClickTrackers = (crid: string | undefined, clickTrackers: string[]): void => {
  clickTrackers
    .forEach((tracker) => {
      logger.debug('trigger clickTracker url', tracker, crid);
      fetch(tracker).catch(() => {
        logger.warn('error while executing clickTracker url', tracker);
      });
    });
};
