import {AdTypes} from 'features/adoppler';
import fetchAd from 'features/adoppler/service/fetch';
import {REQUEST_FAILED_EVENT} from 'shared/constants';
import {eventEmitter} from 'shared/utils/eventEmitter';
import LaunchDarklyService from 'shared/utils/launch-darkly-service';
import {logger as baseLogger} from 'shared/utils/logger';
import {prefetchAdPod} from 'shared/utils/prefetch-service/prefetch';

import type {UseAdReturnType} from 'types';

import type {VastParsedData} from 'types/vast-client';

const logger = baseLogger.child({tag: '[CacheManager]'});

const SKIP_CACHE_WAITING = 'skipCacheWaiting';

/**
 * CacheManager class for managing ad caching and retrieval
 */
export class CacheManager {
  private cacheKeysQueue: number[] = [];
  private adQueue: UseAdReturnType[] = [];
  private currentKeyIndex: number = 0;
  private fillingPromise: Promise<void | undefined> | null = null;

  /**
   * Set the queue of cache keys and start the background process
   * @param {number[]} cacheKeysQueue - Array of keys for fetchAd function
   */
  setBarkerQueue(cacheKeysQueue: number[]) {
    this.cacheKeysQueue = cacheKeysQueue;
    this.currentKeyIndex = 0;
    logger.debug('Cache keys set:', this.cacheKeysQueue);
    this.fillingPromise = this.fillQueueProcess().catch();

    const adamRequestStrategy = LaunchDarklyService.getFlag('adam-video-ad-request-strategy', 'cache');
    eventEmitter.on(REQUEST_FAILED_EVENT, () => {
      if (adamRequestStrategy === 'request') {
        eventEmitter.emit(SKIP_CACHE_WAITING);
      }
    });
  }

  /**
   * Process to fill the queue in order
   */
  private async fillQueueProcess() {
    logger.debug('Starting fill queue process');

    const key = this.cacheKeysQueue[this.currentKeyIndex];
    await this.fetchAdForKey(key);
  }

  /**
   * Fetch an ad pod for a given duration
   * @param {number} duration - Duration of the ad pod in seconds
   * @return {Promise<UseAdReturnType>} Promise resolving to the fetched ad pod
   */
  private async fetchAdPod(duration: number): Promise<UseAdReturnType> {
    const ad = await fetchAd(AdTypes.AdPod, duration * 1000);
    let adPodVasts: VastParsedData[] = [];

    if (ad.adType == AdTypes.AdPod) {
      adPodVasts = await prefetchAdPod(ad);
      logger.debug(`Fetched AdPod VASTs`, adPodVasts);
    }
    return {...ad, adPodVasts};
  }

  /**
   * Fetch ad for a specific key and add it to the queue
   * @param {number} duration - The key to fetch the ad for
   */
  private async fetchAdForKey(duration: number) {
    const key = `cache-${AdTypes.AdPod}-${duration}`.toLowerCase();
    try {
      logger.debug(`Fetching ad for duration: ${duration} seconds`);
      let ad = await this.fetchAdPod(duration);

      if (!ad.adPodVasts?.length) {
        // if no ad is in cache wait `addToCache` event
        const result = await new Promise((resolve) => {
          eventEmitter.on(`AddToCache-${key}`, async () => {
            if (!ad.adPodVasts?.length) {
              logger.debug(`Attempting to add ad to cache for duration: ${duration} seconds`);
              ad = await this.fetchAdPod(duration);
              logger.info(`Fetched ad`, ad);
              if (ad.adPodVasts?.length) {
                resolve(true);
              }
            }
          });
          eventEmitter.on(SKIP_CACHE_WAITING, () => {
            resolve(false);
          });
        }).finally(() => {
          logger.debug('Finished event emitter process');
          eventEmitter.removeAllListeners(`AddToCache-${key}`);
          eventEmitter.removeAllListeners(SKIP_CACHE_WAITING);
        });
        if (!result) {
          logger.debug('Cache waiting aborted');
          return;
        }
      }

      if (ad.adPodVasts?.length) {
        logger.debug(`Added ad to queue for duration: ${duration} seconds`, {ad});

        this.adQueue.push(ad);
      }
    } catch (error) {
      logger.error(`Error fetching ad for key ${key}:`, error);
    }
  }

  /**
   * Get an ad from the queue
   * @return {Ad | null} The next ad in the queue, or null if the queue is empty
   */
  getCacheAd(): UseAdReturnType | null {
    const ad = this.adQueue.shift();
    // Finish promise waiting adding cache event
    eventEmitter.emit(SKIP_CACHE_WAITING);
    if (this.currentKeyIndex < this.cacheKeysQueue.length - 1) {
      this.currentKeyIndex++;
    }
    this.fillQueueProcess().catch();
    logger.debug('Retrieved ad from queue', {adId: ad?.adResponseId});

    return ad || null;
  }

  /**
   * Get an ad from the queue with a timeout
   * @return {Promise<UseAdReturnType | null>} The next ad in the queue, or null if the queue is empty or timeout occurs
   */
  async getRequestAd(): Promise<UseAdReturnType | null> {
    // Create a timeout promise that rejects after 5 seconds
    const timeoutPromise = new Promise<null>((_, reject) => {
      setTimeout(() => {
        reject(new Error('Ad request timed out'));
      }, 5000);
    });

    try {
      // Race between filling promise and timeout
      await Promise.race([
        this.fillingPromise || Promise.resolve(),
        timeoutPromise,
      ]);

      const ad = this.adQueue.shift();
      if (this.currentKeyIndex < this.cacheKeysQueue.length - 1) {
        this.currentKeyIndex++;
      }
      this.fillingPromise = this.fillQueueProcess().catch();
      logger.debug('Retrieved ad from queue', {adId: ad?.adResponseId});

      return ad || null;
    } catch (error) {
      logger.error('Error in getRequestAd:', error);
      return null;
    }
  }

  /**
   * Stop the background process
   */
  stop() {
    eventEmitter.removeAllListeners(REQUEST_FAILED_EVENT);
    eventEmitter.emit(SKIP_CACHE_WAITING);
    logger.debug('Background process stopped');
  }
}

export const cacheManager = new CacheManager();
