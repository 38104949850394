import type {ReactNode} from 'react';

import {v4 as uuid} from 'uuid';

import {getAdPodStartPayload} from 'components/Ads/TypeVideo/helpers/utils';
import {AbstractStrategy} from 'entities/strategies/api/abstract';
import generateAdJSX from 'entities/strategies/ui/generateAdJSX';
import {AdTypes} from 'features/adoppler/enums';
import {generateWorkerScope} from 'shared/api/ad/adoppler/helpers';
import {type AdBlock, type BarkerBlock, type ChannelData, setPreviousEpisode} from 'shared/api/bootstrap-service';
import {
  ADOPPLER_RENDER_EVENT,
  VIDEO_AD_FINISHED_EVENT,
  VIDEO_REACHED_PLAYING_TIME_THRESHOLD_EVENT,
  WORKER_JOB_MAKE_CALL,
  WORKER_REGISTER_CUSTOM_AD_POD,
} from 'shared/constants';
import {subscribe, triggerCustomEvent, unsubscribe} from 'shared/utils';
import {cacheManager} from 'shared/utils/cache-manager';
import {AndroidSDKEvent} from 'shared/utils/eventsSdk';
import LaunchDarklyService from 'shared/utils/launch-darkly-service';
import {logger as baseLogger} from 'shared/utils/logger';

import type {AdConfig, AdStrategyName, UseAdReturnType} from 'types';

import type {AdStrategy} from 'entities/strategies/model';
import type {FlagSet} from 'shared/utils/launch-darkly-service/types.ts';

const logger = baseLogger.child({tag: '[AdopplerBarkerStrategy]'});
const sdkEvent = new AndroidSDKEvent();

// eslint-disable-next-line require-jsdoc
class AdopplerBarkerStrategy extends AbstractStrategy implements AdStrategy {
  videoFinishHandler: EventListener | undefined;
  videoReachedThreshold: EventListener | undefined;

  timeoutId: NodeJS.Timeout | undefined;
  name: AdStrategyName;
  awaitPromise: ((result: boolean) => void) | undefined;
  loopStart: boolean = false;
  channelData: ChannelData | undefined;
  adUnitConfig: AdConfig;
  isStarted: boolean = false;

  index: number;
  showId: string | undefined;
  currentQuartile: number;

  googleCanBePlayed: boolean = false;

  adamRequestStrategy: FlagSet['adam-video-ad-request-strategy'] | null = null;

  /**
   * Creates an instance of the AdopplerRandomWeightedStrategy.
   *
   * @constructor
   * @param {ChannelData | undefined} channelData - DeviceProps we need to pass to handle validation
   * @param {AdConfig} adUnitConfig - DeviceProps we need to pass to handle validation
   */
  constructor(channelData: ChannelData | undefined, adUnitConfig: AdConfig) {
    super();
    this.index = 0;
    this.name = 'adoppler-barker-strategy';
    this.channelData = channelData;
    this.adUnitConfig = adUnitConfig;
    this.showId = adUnitConfig.providers.gabriel.adoppler.config.showId;
    this.currentQuartile = 1;
  }

  /**
   * Run and managing fetching loop
   */
  public startLoop(): void {
    logger.debug('Started strategy cycle', this.channelData, this.adUnitConfig);

    this.adamRequestStrategy = LaunchDarklyService.getFlag('adam-video-ad-request-strategy', 'cache');

    logger.debug(`Chosen '${this.adamRequestStrategy}' strategy to request data`);

    this.loopStart = true;

    if (this.channelData?.episode) {
      setPreviousEpisode(this.channelData.episode);
      sdkEvent.adPodStart(getAdPodStartPayload('', []));
    }

    if (this.channelData && this.adUnitConfig) {
      this.registerCustomAdPodWorkers(this.channelData.blocks);
      cacheManager.setBarkerQueue(this.getCacheKeys(this.channelData.blocks));
      (async () => {
        this.videoFinishHandler = (e) => {
          // should be at least one started without errors
          this.isStarted = this.isStarted || !((e as CustomEvent).detail?.isError);
          // we have active request, we should stop it before send new one on demand
          if (this.timeoutId) {
            clearTimeout(this.timeoutId);
          }

          if (this.awaitPromise) {
            this.awaitPromise(true);
          }
        };

        this.videoReachedThreshold = () => {
          if (this.adamRequestStrategy === 'cache') {
            return;
          }

          // find following adPod duration and make new call
          let ad = this.getFollowingAdPod(this.index + 1);
          if (this.index >= (this.channelData!.blocks.length - 1)) {
            // we have reached last block, following business logic we have to play last possible element
            ad = this.getFollowingAdPod(this.index - 1);
          }

          if (!this.isNextBlockBarker(this.index + 1) && ad) {
            triggerCustomEvent(WORKER_JOB_MAKE_CALL, {scope: generateWorkerScope(AdTypes.AdPod, ad.duration / 1000)});
          }
        };

        subscribe(VIDEO_REACHED_PLAYING_TIME_THRESHOLD_EVENT, this.videoReachedThreshold);
        subscribe(VIDEO_AD_FINISHED_EVENT, this.videoFinishHandler);

        while (this.loopStart) {
          await this.run();
        }
      })();
    }
  }

  private isNextBlockBarker = (index: number) => {
    return Boolean(this.channelData?.blocks[index] && this.channelData?.blocks[index].type === 'barker');
  };

  /**
   * Get next available AdPod
   * @param {number} index
   * @return {AdBlock | undefined}
   */
  private getFollowingAdPod = (index: number): AdBlock | undefined => {
    return this.channelData?.blocks.slice(index).find((block) => block.type === 'ad') as AdBlock;
  };

  /**
   * Unsubscribe from active videoHandler
   */
  private unsubscribeVideoHandler() {
    if (this.videoFinishHandler) {
      unsubscribe(VIDEO_AD_FINISHED_EVENT, this.videoFinishHandler);
      this.videoFinishHandler = undefined;
    }
  }

  /**
   * Unsubscribe from active videoHandler
   */
  private unsubscribeVideoReachedThreshold() {
    if (this.videoReachedThreshold) {
      unsubscribe(VIDEO_REACHED_PLAYING_TIME_THRESHOLD_EVENT, this.videoReachedThreshold);
      this.videoReachedThreshold = undefined;
    }
  }

  /**
   * Method to play video
   * @param {UseAdReturnType} response
   * @private
   * @return {Promise<boolean>}
   */
  private async playVideo(
    response: UseAdReturnType,
  ): Promise<boolean> {
    return new Promise((r) => {
      this.awaitPromise = r;
      triggerCustomEvent(ADOPPLER_RENDER_EVENT, {...response, disableAdEvents: true});
    });
  }

  /**
   * Method to play video
   * @param {string} barkerUrl
   * @private
   * @return {Promise<boolean>}
   */
  private async playBarker(
    barkerUrl: string,
  ): Promise<boolean> {
    return new Promise((r) => {
      this.awaitPromise = r;

      triggerCustomEvent(ADOPPLER_RENDER_EVENT, {
        adType: AdTypes.Barker,
        adSettings: [{adUrls: [barkerUrl],
          barkerData: {
            channelId: String(this.showId),
            episodeId: String(this.channelData?.episode),
            blockId: this.index.toString(),
          },
        }],
        adResponseId: '',
      });
    });
  }

  /**
   * Register new worker
   * @param {Array<BarkerBlock | AdBlock>} blocks
   * @protected
   * @return {number[]}
   */
  protected getCacheKeys(blocks: Array<BarkerBlock | AdBlock>): number[] {
    return blocks.filter((block) => block.type === 'ad')
      .map((block) =>
        (block as AdBlock).duration / 1000,
      );
  }

  /**
   * Register new worker
   * @param {Array<BarkerBlock | AdBlock>} blocks
   * @protected
   */
  protected registerCustomAdPodWorkers(blocks: Array<BarkerBlock | AdBlock>) {
    const customDurations = blocks.filter((block) => block.type === 'ad')
      .map((block) => (block as AdBlock).duration / 1000);
    (new Set(customDurations)).forEach((duration) => {
      triggerCustomEvent(WORKER_REGISTER_CUSTOM_AD_POD, {duration});
      logger.debug('Registering custom ad pod worker with duration:', {duration});
    });
  }

  /**
   * Run loop
   * @private
   */
  private async run() {
    if (!this.channelData) {
      logger.warn('No channel data found, aborting strategy loop.');
      return;
    }
    const blocks = this.channelData.blocks;
    const block = blocks[this.index];
    logger.debug('loop run', {block, index: this.index});

    if (block?.type === 'ad') {
      let response = null;
      if (this.adamRequestStrategy === 'cache') {
        response = cacheManager.getCacheAd();
      } else if (this.adamRequestStrategy === 'request') {
        response = await cacheManager.getRequestAd();
      }

      logger.debug('get response from cache', response);
      if (response) {
        await this.playVideo(response);
      }
    } else if (block?.type === 'barker') {
      await this.playBarker(block.url);
    }

    if (this.index < blocks.length - 1) {
      this.index++;
    } else {
      this.index = 0;
    }
  }

  /**
   * Stop running fetching loop
   */
  public stopLoop(): void {
    this.loopStart = false;
    if (this.timeoutId) {
      clearInterval(this.timeoutId);
    }

    cacheManager.stop();
    this.unsubscribeVideoHandler();
    this.unsubscribeVideoReachedThreshold();

    // show default logo
    triggerCustomEvent(ADOPPLER_RENDER_EVENT, {adType: AdTypes.DefaultTelly, adSettings: [], adResponseId: uuid()});
  }

  /**
   * Retrieves an ad using the strategy.
   *
   * @return {ReactNode | null} The JSX element representing the selected ad.
   */
  public getAd(): ReactNode | null {
    return generateAdJSX(this.name, this.startLoop.bind(this), this.stopLoop.bind(this), this.googleCanBePlayed);
  }
}

export {AdopplerBarkerStrategy};
