import {getDefaultAdConfig} from 'config';
import {
  AdTypes, type UserBodyRequest, type AppInfoBodyRequest,
  type DeviceInfoBodyRequest, type Impression, type AdopplerResponse, type ExtBodyRequest,
} from 'features/adoppler';
import {bidRequestMetrics, gabrielCacheMetrics} from 'shared/api/metrics-service';
import {getUserId, transformIPAddress, type DeviceIpAddresses} from 'shared/utils';

import type {DeviceInfo, Gabriel} from 'types';

import {endpoints} from './constants';

import type {EndpointKey} from './types.ts';
import type {NativeAd} from 'features/adoppler/types/native';

/**
 * Returns the request body for app information.
 *
 * @return {AppInfoBodyRequest} The request body containing app name and version.
 */
export const getAppInfoBodyRequest = (): AppInfoBodyRequest => {
  return {
    app: {
      name: 'Telly',
      ver: __APP_VERSION__,
    },
  };
};

/**
 * Make and get value
 * @param {string | undefined} value
 * @return {string}
 */
export const getRequestBodyValue = (value: string | undefined): string => {
  if (!value) {
    return 'false';
  }

  return value;
};

/**
 * Make ext object
 * @param {DeviceInfo | null} deviceInfo
 * @param {boolean} isDongleAvailable
 * @param {string} givn
 * @return {ExtBodyRequest}
 */
export const getExtRequestBody = (
  deviceInfo: DeviceInfo | null,
  isDongleAvailable: boolean,
  givn: string,
): ExtBodyRequest => {
  return {
    givn: givn,
    ecpDeviceSupported: +isDongleAvailable, // convert bool to int
    ecpClientVersion: getRequestBodyValue(deviceInfo?.ecpClientVersion),
    ecpServerVersion: getRequestBodyValue(deviceInfo?.ecpServerVersion),
  };
};

/**
 * Returns the request body for device information.
 *
 * @param {DeviceInfo} deviceInfo - The device information.
 * @param {DeviceIpAddresses | undefined} ipAddresses - The device IP addresses.
 * @return {DeviceInfoBodyRequest | null} The request body for device information.
 */
export const getDeviceInfoBodyRequest = (
  deviceInfo: DeviceInfo,
  ipAddresses: DeviceIpAddresses | undefined,
): DeviceInfoBodyRequest | null => {
  if (!deviceInfo) return null;

  const ifa = deviceInfo.ad_info.ifa;
  const ip = deviceInfo.ip_address;

  const ipAddressesSection = {
    ip: undefined,
    ipv6: undefined,
    ...transformIPAddress(ip),
  };

  if (!ipAddressesSection.ip && ipAddresses?.ip) {
    ipAddressesSection.ip = ipAddresses.ip;
  }

  if (!ipAddressesSection.ipv6 && ipAddresses?.ipv6) {
    ipAddressesSection.ipv6 = ipAddresses.ipv6;
  }

  const ifatype = deviceInfo.ad_info.ifa_type;

  return {
    ...ipAddressesSection,
    ua: navigator.userAgent,
    lmt: 0,
    ifa,
    ext: {
      ifa_type: ifatype,
    },
  };
};

/**
 * The requested dimensions of a video ad
 */
type VideoDimension = {
  w: number;
  h: number;
};

/**
 * Get the requested video dimensions based on the ad unit config
 *
 * For Eve's fullscreen template, return 512x288.
 * For Adam ad units, return 1095x616.
 *
 * @return {VideoDimension} The video width and height
 */
export const getVideoDimensions = (): VideoDimension => {
  const defaultAdUnitConfig = getDefaultAdConfig();
  const template = defaultAdUnitConfig.providers.gabriel.adoppler.template;
  return template === 'eve-screen-fullscreen' ? {
    // for Eve
    w: 512,
    h: 288,
  } : {
    // for Adam
    w: 1095,
    h: 616,
  };
};

/**
 * Get the impression data for a given ad type.
 *
 * @param {number | string} adType - The ad type to retrieve the impression data for.
 * @return {Impression[] | undefined} - An array of impression data objects based on the ad type, or undefined if the ad type is not recognized.
 *
 * @example
 * const adType = AdTypes.HtmlBanner;
 * const impressionData = getImpressionByType(adType);
 * // impressionData will be an array of objects with native impression data for HtmlBanner or Png ad types.
 */
export function getImpressionByType(adType: number | string): Impression | undefined {
  const videoDimension = getVideoDimensions();
  switch (adType) {
    case AdTypes.MultiBid:
      return [
        {
          id: '1',
          native: {
            ver: '1.2',
            request: {
              assets: [
                {
                  id: 1,
                  required: 1,
                  img: {
                    type: AdTypes.Png,
                  } as NativeAd.Img,
                },
              ],
            },
          },
        }, {
          id: '2',
          native: {
            ver: '1.2',
            request: {
              assets: [
                {
                  id: 1,
                  required: 1,
                  img: {
                    type: AdTypes.Expandable.valueOf(),
                    w: 1920,
                    h: 360,
                  },
                },
                {
                  id: 2,
                  required: 1,
                  img: {
                    type: AdTypes.Expandable.valueOf(),
                    w: 514,
                    h: 290,
                  },
                },
              ],
            },
          },
        }, {
          id: '3',
          native: {
            ver: '1.2',
            request: {
              assets: [
                {
                  id: 1,
                  required: 1,
                  img: {
                    type: AdTypes.Expandable.valueOf(),
                    w: 1920,
                    h: 360,
                  },
                },
                {
                  id: 2,
                  required: 1,
                  img: {
                    type: AdTypes.Expandable.valueOf(),
                    w: 300,
                    h: 250,
                  },
                },
              ],
            },
          },
        }, {
          id: '4',
          video: {
            mimes: ['video/x-m4v', 'video/mp4', 'video/webm', 'video/3gp', 'video/mkv'],
            minduration: 1,
            maxduration: 60,
            protocols: [1, 2, 3, 4, 5, 6, 7, 8, 11, 12, 13, 14, 15, 16],
            ...videoDimension,
          },
        }, {
          id: '5',
          native: {
            ver: '1.2',
            request: {
              assets: [
                {
                  id: 1,
                  required: 1,
                  data: {
                    type: <number>AdTypes.HtmlBanner,
                  },
                },
              ],
            },
          },
        },
        {
          id: '6',
          native: {
            ver: '1.2',
            request: {
              assets: [{
                id: 1,
                required: 1,
                img: {
                  type: AdTypes.EveFullscreenTakeover.valueOf(),
                  w: 300,
                  h: 250,
                },
              },
              {
                id: 2,
                required: 1,
                data: {
                  type: AdTypes.EveFullscreenTakeover.valueOf(),
                },
              }],
            },
          },
        },
      ];
    case AdTypes.HtmlBanner:
      return [
        {
          id: '1',
          native: {
            ver: '1.2',
            request: {
              assets: [
                {
                  id: 1,
                  required: 1,
                  data: {
                    type: <number>AdTypes.HtmlBanner,
                  },
                },
              ],
            },
          },
        },
      ];
    case AdTypes.Png:
      return [
        {
          id: '1',
          native: {
            ver: '1.2',
            request: {
              assets: [
                {
                  id: 1,
                  required: 1,
                  img: {
                    type: adType,
                    w: 514,
                    h: 290,
                  } as NativeAd.Img,
                },
              ],
            },
          },
        },
      ];
    case AdTypes.InhousePng:
    case AdTypes.AdamPngUp:
    case AdTypes.AdamPngDown:
    case AdTypes.AdamPngCenter:
    case AdTypes.EveFullscreen:
      return [
        {
          id: '1',
          native: {
            ver: '1.2',
            request: {
              assets: [
                {
                  id: 1,
                  required: 1,
                  img: {
                    type: adType,
                  } as NativeAd.Img,
                },
              ],
            },
          },
        },
      ];

    case AdTypes.Expandable:
      return [
        {
          id: '1',
          native: {
            ver: '1.2',
            request: {
              assets: [
                {
                  id: 1,
                  required: 1,
                  img: {
                    type: AdTypes.Expandable.valueOf(),
                    w: 1920,
                    h: 360,
                  },
                },
                {
                  id: 2,
                  required: 1,
                  img: {
                    type: AdTypes.Expandable.valueOf(),
                    w: 514,
                    h: 290,
                  },
                },
              ],
            },
          },
        },
      ];

    case AdTypes.Video:
      return [
        {
          id: '1',
          video: {
            mimes: ['video/x-m4v', 'video/mp4', 'video/webm', 'video/3gp', 'video/mkv'],
            minduration: 1,
            maxduration: 60,
            protocols: [1, 2, 3, 4, 5, 6, 7, 8, 11, 12, 13, 14, 15, 16],
            ...videoDimension,
          },
        },
      ];

    case AdTypes.AdPod:
      return [
        {
          id: '1',
          video: {
            mimes: ['video/x-m4v', 'video/mp4', 'video/webm', 'video/3gp', 'video/mkv'],
            minduration: 1,
            maxduration: 120,
            poddur: 120,
            protocols: [1, 2, 3, 4, 5, 6, 7, 8, 11, 12, 13, 14, 15, 16],
            ...videoDimension,
          },
        },
      ];
  }
}

/**
 * Form UserBodyRequest part of request
 * @param {DeviceInfo | undefined} deviceInfo - The device information.
 * @return {UserBodyRequest | null}
 */
export const getUserRequestBody = (deviceInfo: DeviceInfo | null): UserBodyRequest | null => {
  if (!deviceInfo) {
    return null;
  }

  const eids = [] as UserBodyRequest['eids'];

  // UID2
  const uid2Id = getUserId(deviceInfo, 'uid2');
  if (uid2Id) {
    eids.push({
      source: 'http://uidapi.com',
      uids: [{id: uid2Id}],
    });
  }

  // Criteo
  const criteoMd5 = getUserId(deviceInfo, 'md5', 'criteo');
  const criteoSha256 = getUserId(deviceInfo, 'sha256', 'criteo');
  if (criteoMd5 || criteoSha256) {
    const criteoUids = [];
    if (criteoMd5) {
      criteoUids.push({
        id: criteoMd5,
        atype: 3,
        ext: {stype: 'hemmd5'},
      });
    }
    if (criteoSha256) {
      criteoUids.push({
        id: criteoSha256,
        atype: 3,
        ext: {stype: 'hemsha256'},
      });
    }
    eids.push({
      source: 'https://criteo.com',
      uids: criteoUids,
    });
  }

  // APS
  const apsId = getUserId(deviceInfo, 'sha256', 'aps');
  if (apsId) {
    eids.push({
      source: 'https://aps.amazon.com',
      uids: [{id: apsId}],
    });
  }

  // Sending Analytics
  if (deviceInfo.alternateIds) {
    bidRequestMetrics.emitEvent('HouseholdIDs', [{Name: 'property', Value: 'alternateIds'}]);
  } else {
    bidRequestMetrics.emitEvent('HouseholdIDs', [{Name: 'property', Value: 'user_identifiers'}]);
  }

  return {
    id: deviceInfo.ad_info.ifa,
    eids,
  };
};

export const getAcrUrl = (adopplerConfig: Gabriel['adoppler']): string => {
  return adopplerConfig.apiEndpoints.acr || getUrlByType(adopplerConfig.config.elementalEnv);
};

export const getRegularUrl = (adopplerConfig: Gabriel['adoppler']): string => {
  return adopplerConfig.apiEndpoints.regular || getUrlByType(adopplerConfig.config.elementalEnv);
};

/**
 * Define api url
 * @param {env} env
 * @return {string}
 */
export const getUrlByType = (env: EndpointKey | undefined): string => {
  return env && endpoints[env] ? endpoints[env] : endpoints.production;
};

/**
 * Get random item from the cache
 * @param {Request[]} list
 * @return {Request}
 */
function getRandom(list: Request[]): Request {
  return list[Math.floor((Math.random() * list.length))];
}

/**
 * Get random item for the type
 * @param {string} adType
 * @return {Promise<AdopplerResponse>}
 */
export const getRandomItem = async (adType: AdTypes): Promise<AdopplerResponse> => {
  return getAdFromCache(`cache-${adType}`.toLowerCase());
};

/**
 * Get random Ad from opened cache
 * @param {string} scope
 * @return {Promise<AdopplerResponse>}
 */
const getAdFromCache = async (scope: string): Promise<AdopplerResponse> => {
  const cacheInstance = await caches.open(scope);
  if (!cacheInstance) {
    throw new Error('No ad found in the cache...');
  }
  const cacheItems = await cacheInstance.keys();
  if (cacheItems.length < 1) {
    throw new Error('No ad found in the cache...');
  }

  // get response item
  const randomItem = getRandom(cacheItems as []);
  const adResponse = await cacheInstance.match(randomItem);
  if (!adResponse) {
    throw new Error('No ad found in the cache...');
  }

  // remove item we got
  await cacheInstance.delete(randomItem);
  gabrielCacheMetrics.emitEvent('RemovedFromCache', [
    {Name: 'provider', Value: 'Elemental'},
    {Name: 'namespace', Value: scope},
  ]);
  return adResponse.json();
};

/**
 * Get AdPod from cache based on duration
 * @param {number} duration
 * @return {Promise<AdopplerResponse>}
 */
export const getAdPodFromCache = async (duration: number): Promise<AdopplerResponse> => {
  let item;
  try {
    item = getAdFromCache(`cache-${AdTypes.AdPod}-${duration/1000}`.toLowerCase());
  } catch {
    item = getRandomItem(AdTypes.AdPod);
  }

  return item;
};

/**
 * Generate worker scope name
 * @param {AdTypes} adType
 * @param {number} duration - use this for custom video types like AdPod
 * @return {string}
 */
export const generateWorkerScope = (adType: AdTypes, duration?: number): string => {
  return (duration)? `./adoppler-${adType.toString()}-${duration}`.toLowerCase() : `./adoppler-${adType}`.toLowerCase();
};

/**
 * Find time left
 * @param {number} played
 * @param {number} duration
 * @return {number}
 */
export const playingTimeLeft = (played: number, duration: number): number => {
  return Math.trunc(duration - played);
};
