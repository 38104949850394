import {ANDROID_SDK_EVENT} from 'shared/constants';

import type {AdPodBeginEvent, AdPodFinishEvent} from 'types';

import {triggerCustomEvent} from './event';
import {logger} from './logger';
import monitorInternetSpeed from './monitorInternetSpeed';

/**
 * Class describes Android event we generated for external listeners
 */
class AndroidSDKEvent {
  /**
   * Sends an event to the TellyAds SDK.
   *
   * This uses TellyAds.onCallback if available, otherwise it falls back to triggerCustomEvent.
   *
   * @param {object} payload - Payload to send to the SDK
   */
  private sendEventToSdk(payload: object): void {
    try {
      if (window.TellyAds?.onCallback) {
        logger.debug('Sending event to TellyAds SDK', {payload});

        window.TellyAds.onCallback(JSON.stringify(payload));

        return;
      }

      triggerCustomEvent(ANDROID_SDK_EVENT, payload);
    } catch (error) {
      logger.error('Failed to send event to SDK', {error, payload});
      triggerCustomEvent(ANDROID_SDK_EVENT, payload);
    }
  }

  /**
   * Generate paid ad requested event
   * @param {object} payload - body we pass to event
   */
  public paidAdRequested(payload: object): void {
    this.sendEventToSdk({
      event: 'waterfall.paidAd.requested',
      payload: payload,
    });
  }

  /**
   * Generate house AD get played event
   * @param {object} payload - body we pass to event
   * @example
   * Have to be implemented.
   */
  public playingHouseAd(payload: object): void {
    throw new Error(payload.toString());
  }

  /**
   * Generate default filler get played event
   * @param {object} payload - body we pass to event
   * @example
   * Have to be implemented.
   */
  public playingDefaultFiller(payload: object): void {
    throw new Error(payload.toString());
  }

  /**
   * Generate paid ad not found event
   * @param {object} payload - body we pass to event
   * @example
   * Have to be implemented.
   */
  public paidAdNotFound(payload: object): void {
    throw new Error(payload.toString());
  }

  /**
   * Generate gabriel cannot be initialized event
   * @param {string} message - exact error message
   */
  public errorGabrielInit(message: string): void {
    this.sendEventToSdk({
      event: 'gabriel.system.error',
      payload: {message},
    });
  }

  /**
   * Generate gabriel cannot be initialized event
   */
  public gabrielIsReady(): void {
    this.sendEventToSdk({
      event: 'gabriel.system.ready',
      payload: {},
    });
  }

  /**
   * Publish event for ads started
   * @param {string} adsType
   * @param {string | undefined} creativeId
   */
  public adStarted(adsType: string, creativeId: string | undefined): void {
    this.sendEventToSdk({
      event: 'gabriel.ad.status',
      payload: {
        type: adsType,
        action: 'begin',
        creativeId,
      },
    });
  }

  /**
   * Publish event for ads finished
   * @param {string} adsType
   * @param {string | undefined} creativeId
   */
  public adFinished(adsType: string, creativeId: string | undefined): void {
    this.sendEventToSdk({
      event: 'gabriel.ad.status',
      payload: {
        type: adsType,
        action: 'finish',
        creativeId,
      },
    });
  }

  /**
   * Publish event for adPod get started
   * @param {AdPodBeginEvent} payload
   */
  public adPodStart(payload: AdPodBeginEvent): void {
    this.sendEventToSdk({
      event: 'gabriel.pod.status',
      payload: payload,
    });
  }

  /**
   * Publish event for adPod is finished
   * @param {AdPodFinishEvent} payload
   */
  public adPodStop(payload: AdPodFinishEvent): void {
    this.sendEventToSdk({
      event: 'gabriel.pod.status',
      payload: payload,
    });
  }

  /**
   * Publish event interface to handle situations where no ads can be displayed,
   * prompting the client TV to switch screens.
   */
  public noAdDisplay(): void {
    this.sendEventToSdk({
      event: 'gabriel.system.skip',
      payload: {
        reason: 'NO_AD_DISPLAY',
      },
    });
  }

  /**
   * Publishes a heartbeat event to indicate the active status of the Gabriel application.
   */
  public sendHeartbeat(): void {
    this.sendEventToSdk({
      event: 'gabriel.system.heartbeat',
      payload: {},
    });
  }

  /**
   * Publishes a heartbeat event and internet speed to indicate the active status of the Gabriel application.
   */
  public sendHeartbeatWithInternetSpeedMonitoring(): void {
    this.sendHeartbeat();
    monitorInternetSpeed();
  }
}

export {AndroidSDKEvent};
